/**
 * Created by osirvent on 18/11/2015.
 */
angular
    .module('annexaApp')
    .constant('ContextMenu', window.$.contextMenu)
    .constant('api', {
        getEntity: './api/entities/findById',
		getTasksByUser:'./api/tasks/findByUserIdAndRemovedDateIsNull',
        getTasksByUserFilter:'./api/tasks/findByUserIdAndEndedTaskAndRemovedDateIsNull',
        countTasksByUserFilter:'./api/tasks/countByUserIdAndEndedTaskAndRemovedDateIsNull',
        getAlertsByFilter:'./api/alerts/getAlertsByFilter',
        getAlertsByFilterPaginated:'./api/alerts/getAlertsByFilterPaginated',
        updateReadAlerts:'./api/alerts/updateReadAlerts',
        sendEMail: './api/email?',
        getTasks:'./api/tasks',
        millisecondsInterval:10000
    })
    .constant('globalModals', {
        reassignTask: {
            title: 'global.literals.reassignTask',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'newUser',
                                type: 'annexaLoadUserRow',
                                className: '',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.user',
                                    required: true,
                                    focus: false,
                                    options: [],
                                    loadFunction: function () {}
                                },
                                controller: function ($scope, $rootScope, RestService) {
                                    $scope.options.templateOptions.loadFunction = function(value) {
                                        if(!value && !value.val) {
                                            return [];
                                        }

                                        if(value.val != '*' && value.val.length < 3) {
                                            return [];
                                        }

                                        if(value.val == '*') {
                                            value.val = '';
                                        }

                                        return RestService.loadData('common', 'User', value.val)
                                            .then(function(data) {
                                                var users = [];

                                                if(data.data && data.data.content && data.data.content.length > 0) {
                                                    _.forEach(JSOG.decode(data.data.content), function(val) {
                                                        var name = val.name + ' ' + val.surename1;

                                                        if(val.surename2) {
                                                            name += ' ' + val.surename2;
                                                        }

                                                        users.push({ 'id':  val.id, 'user': val, 'value': name });
                                                    });
                                                }

                                                return users;
                                            }).catch(function() {
                                                return [];
                                            });

                                    }
                                },
                                validators:{
                                    "requiredCouncillor": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = false;
                                            if(modelValue && modelValue.id){
                                                valid = true;
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'comments',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.reason',
                                    required: true,
                                    focus: false
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        adminBaseNew: {
            title: 'New element',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'language1',
                                type: 'annexaInputLanguage',
                                className: 'col-sm-12',
                                validators:{
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(scope.languages){
                                                angular.forEach(scope.languages, function (value2, key2) {
                                                    if(value2 && value2.column) {
                                                        if (modelValue && !modelValue[value2.column]) {
                                                            valid = false;
                                                        }
                                                    }
                                                });
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                },
                                templateOptions: {
                                    id: 'language',
                                    modelField: '',
                                    type: 'text',
                                    label: 'global.literals.name',
                                    disposition: 'horitzontal21',
                                    required: true,
                                    focus: true
                                },
                                controller: function ($scope, $rootScope) {
                                    $scope.languages = $rootScope.app.languagedef;
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        adminBaseEdit: {
            title: 'Edit element',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field type="language" label="global.literals.name" model-value="modal.data" model-property="{{modal.languageColumn}}" save="modal.updateElement(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        dossierDocFromTemplate: {
            title: 'global.literals.dossier_doc_from_template_title',
            size: 'modal-md',
            icon: 'fa fa-user',
            annexaFormly: {},
            boxes: [],
            alerts: [],
            submitModal: function () {}
        },
        receiverNew: {
            title: 'global.literals.new_receiver_modal_title',
            size: 'modal-md',
            icon: 'fa fa-user',
            annexaFormly: {},
            boxes: [],
            alerts: [],
            submitModal: function () {}
        },
        registerNew: {
            title: 'global.literals.new_register_modal_title',
            size: 'modal-lg',
            icon: 'fa fa-user',
            annexaFormly: {},
            boxes: [],
            alerts: [],
            submitModal: function () {}
        },
        receiverAdd: {
            title: 'global.literals.receiver',
            size: 'modal-sm',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
                                key: 'idReceiver',
                                type: 'annexaHidden',
                                className: '',
                                templateOptions: {
                                    type: 'hidden',
                                    label: '',
                                    required: false
                                }
                            },
                        	{
                        		key: 'thirdType',
                        		type: 'annexaRadioCheckboxRow',
                        		className: 'col-sm-12',
                        		defaultValue: 'interested',
                        		data: {
                                    informed: true,
                                    row: true,
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                        		templateOptions: {
                        			type: 'radio',
                        			radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: true,
                                    validate: false,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'id',
                                    labelProp: 'description',
                                    label: 'global.literals.type'
                        		},
                        		controller: function ($scope, $filter) {
                                    $scope.to.options = [
                                        {id:'interested', description: $filter("translate")('global.literals.interested')},
                                        {id:'representative', description: $filter("translate")('global.literals.representative')}
                                    ];
                                }
                        	},
                            {
                                key: 'representated',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.representated',
                                    valueProp: 'id',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false
                                },
                                hideExpression: function($viewValue, $modelValue, scope) {
                                	return scope.model.thirdType != 'representative';
                                },
                                controller: ['$scope', function($scope) {
                                    $scope.to.options = [];

                                    if ($scope.formState && $scope.formState.editReceiver) {
                                    	//Editar destinatario
                                    	var idReceiver = $scope.formState.idReceiver;
                                    	var idThird = $scope.formState.idThird;
                                    	var idReceiverRepresentated = $scope.formState.idReceiverRepresentated;                                    	
                                    	
                                    	var representeds = $linq($scope.formState.data).where("x => x.representated != null && x.representated.id != " + idReceiverRepresentated).select("x => x.representated").toArray();
                                    	if (idReceiver) {
                                    		var interested = $linq($scope.formState.data).where("x => x.interested && x.id != " + idReceiver).except(representeds,"(x,y) => x.id == y.third.id").toArray();
                                    	} else {
                                    		var interested = $linq($scope.formState.data).where("x => x.interested && x.third.id != " + idThird).except(representeds,"(x,y) => x.id == y.third.id").toArray();
                                    	}
                                    } else {
                                    	//Nuevo destinatario
                                    	var representeds = $linq($scope.formState.data).where("x => x.representated != null").select("x => x.representated").toArray();
                                    	var interested = $linq($scope.formState.data).where("x => x.interested").except(representeds,"(x,y) => x.id == y.third.id").toArray();
                                    }

                                    _.forEach(interested, function (value) {
                                        var representated = value.third;
                                        if(value.third.name) {
                                            representated.label = value.third.name + ' ' + value.third.surename1 + ((value.third.surename2)? ' ' + value.third.surename2 : '' ) + ' (' + value.third.identificationDocument + ')';
                                        }else{
                                            representated.label = value.third.corporateName + ' (' + value.third.identificationDocument + ')';
                                        }
                                        $scope.to.options.push(representated);
                                    });
                                }]
                            },
                            {
                                key: 'roleInterested',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.optionsRoleInterested | filter: $select.search',
                                    label: 'global.literals.roleInterested',
                                    valueProp: 'id',
                                    labelProp: 'label',
                                    placeholder: '',
                                    options: [],
                                    required: false,
                                    focus: false
                                },   
                                hideExpression: function($viewValue, $modelValue, scope) {
                                	var hide = true;
                                	if (scope.model.thirdType == 'interested' && scope.$root.existsOptionsRoleInterested) {
                                		hide = false;
                                	}
                                	return hide;
                                },
                                controller: ['$scope', 'RestService','Language', 'TramFactory', function($scope, RestService, Language, TramFactory) {
                                	$scope.to.labelProp = Language.getActiveColumn();
                                	$scope.to.optionsRoleInterested = TramFactory.optionsRoleInterested;                                	
                                }]                                
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {
            }
        },
        receiverEdit: {
            title: 'global.literals.edit_receiver_modal_title',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'editReceiver',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'checkHTML',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: '',
                                    valueProp: 'value',
                                    labelProp: 'label',
                                    labelHtml: 'labelHtml',
                                    placeholder: '',
                                    options: [],
                                    required: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    }
                ]
            },
            submitModal: function () {
            }
        },
        taskNew: {
            title: 'global.literals.new_task_title',
            size: '',
            icon: 'fa fa-check-square-o',
            annexaFormly: {
            },
            submitModal: function () {
            }
        },
        taskEdit: {
            title: 'global.literals.edit_task_title',
            size: '',
            icon: 'fa fa-check-square-o',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field is-read-only="{{modal.isCreator && !modal.data.startDate && !modal.data.endDate}}" type="loadUser" label="global.literals.assign_to" model-value="modal.userAux" model-property="user" save="modal.updateTask(val, prop)" blur="ignore" search="modal.searchTasks(value)"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field is-read-only="{{modal.isCreator && !modal.data.startDate && !modal.data.endDate}}" type="textarea" label="global.literals.description" model-value="modal.data.description" model-property="description" save="modal.updateTask(val, prop)" blur="ignore" maxlength="500" rows="3"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field is-read-only="{{modal.isCreator && !modal.data.startDate && !modal.data.endDate}}" type="number" label="global.literals.days" model-value="modal.data.days" model-property="days" save="modal.updateTask(val, prop)" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row" ng-if="modal.data.dossierTransaction">',
                '   <div class="col-sm-12">',
                '       <label class="small m-b-0">',
                '           <span translate="global.literals.dossier">Dossier</span>',
                '       </label>',
                '   </div>',
                '</div>',
                '<div class="row m-b-sm" ng-if="modal.data.dossierTransaction">',
                '   <div class="col-sm-12">',
                '       <href-permission object-type="DOSSIER_TRANSACTION" object="modal.data.dossierTransaction" name-href="modal.data.dossierTransaction.dossier.dossierNumber + \' \' + modal.data.dossierTransaction.dossier.subject" class-href="text-primary _400 small-lg"/>',
                '   </div>',
                '</div>',
                '<div class="row" ng-if="modal.data.registerEntry">',
                '   <div class="col-sm-12">',
                '       <label class="small m-b-0">',
                '           <span translate="global.literals.regFrom">Register entry</span>',
                '       </label>',
                '   </div>',
                '</div>',
                '<div class="row m-b-sm" ng-if="modal.data.registerEntry">',
                '   <div class="col-sm-12">',
                '       <annexa-href-permission-reg-input register-entry="modal.data.registerEntry"></annexa-href-permission-reg-input>',
                '   </div>',
                '</div>',
                '<div class="row" ng-if="modal.data.document">',
                '   <div class="col-sm-12">',
                '       <label class="small m-b-0">',
                '           <span translate="global.literals.document">Document</span>',
                '       </label>',
                '   </div>',
                '</div>',
                '<div class="row m-b-sm" ng-if="modal.data.document">',
                '   <div class="col-sm-12">',
                '       <annexa-href-permission-document document="modal.data.document" name-properties="\'code\'" origin="TASK"></annexa-href-permission-document>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs p-x">',
                '   <label class="small m-b-0" translate="global.literals.comments"></label>',
                '</div>',
                '<div class="row p-t-xs p-x" ng-repeat="comment in modal.data.taskComments">',
                '   <div class="col-sm-12 b-b p-b-sm m-b-xs">',
                '       <div class="row text-grey">' ,
                '           <div class="col-sm-4">',
                '               <span class="small">{{comment.createdDate | date:\'dd/MM/yyyy HH:mm:ss\' }}</span>',
                '           </div>',
                '           <div class="col-sm-8">',
                '              <span class="small">{{comment.createdUser.name}} {{comment.createdUser.surename1}} {{(comment.createdUser.surename2)? comment.createdUser.surename2 : \'\'}}</span>',
                '           </div>',
                '       </div>',
                '       <div class="row ">' ,
                '           <div class="col-sm-12">',
                '               {{comment.comments}} ',
                '           </div>',
                '       </div>',
                '   </div>',
                '</div>',
                '<div class="row p-x-sm m-t">',
                '   <div class="col-xs-10 p-l">',
                '       <textarea rows="3" class="form-control" ng-model="modal.comment" maxlength="5000" placeholder="{{\'global.literals.newComment\' | translate}}"></textarea>',
                '   </div>',
                '   <div class="col-xs-2">',
                '       <button type="button" ng-click="modal.addTaskComment()" ng-disabled="!modal.comment" class="btn btn-sm grey inline">',
                '           {{\'global.literals.add\' | translate}}',
                '       </button>',
                '   </div>',
                '</div>'
            ]
        },
        taskUpdateComment: {
            title: 'global.literals.update_comment_task_title',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'task.comments',
                                type: 'annexaTextArea',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: '',
                                    required: false,
                                    focus: true
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        newFolder: {
            title: 'global.literals.newFolder',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'name',
                        type: 'annexaInputRow',
                        className: 'col-sm-12',
                        data: {
                            row: true,
                            colClass: '',
                            labelClass: 'label-strong',
                            informed: true,
                            clear: function($event,model,key, $select) {
                                $event.stopPropagation();
                                model[key] = undefined;
                                if($select) {
                                    $select.selected = undefined;
                                    $select.search = undefined;
                                }
                            }
                        },
                        templateOptions: {
                            type: 'text',
                            label: 'global.literals.name',
                            required: true,
                            focus: false
                        }
                    }
                ]
            },
            submitModal: function () {
            }
        },
        cancelDossier: {
            title: 'global.tram.literals.cancel_dossier',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'reason',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.reason',
                                    required: true,
                                    focus: true
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        completeTransactionPopup:{
            title: 'global.tram.literals.complete_transaction_title',
            size: 'modal-lg',
            icon: 'fa fa-check',
            submitModal: function () {
            },
            alerts: []
        },
        completeTransactionPopupGuided:{
            title: 'global.tram.literals.complete_transaction_title',
            size: 'modal-lg',
            icon: 'fa fa-check',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'nextDossierTransactionInfo',
                                type: 'annexaNextDossierTransactionInfoRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    options:{},
                                    transaction:{}
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        goBackTransactionPopup:{
            title: 'global.tram.literals.goback_transaction_title',
            size: '',
            icon: 'fa fa-mail-reply',
            annexaFormly: {
                fields: [
                    {
                        key: 'motiu',
                        type: 'annexaTextAreaRow',
                        className: 'col-sm-12 daughter-label-strong',
                        data: {
                            informed: true,
                            row: true
                        },
                        templateOptions: {
                            type: 'text',
                            required: true,
                            focus: false,
                            label: '',
                            updateOnBlur:false
                        }
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        goBackTransactionPopupWizard: {
            title: 'global.tram.literals.goback_transaction_title',
            size: 'modal-lg',
            icon: 'fa fa-mail-reply',
            wizard: {
                modal: undefined,
                finishWizard: function () {
                    if(this.modal) {
                        this.modal.submitModal();
                    }
                },
                exitValidation: function(form) {
                    return form && !form.$invalid;
                },
                steps: [
                    {
                        title: 'global.literals.rejectReason',
                        key: 'deleteTransaction',
                        disabled: false,
                        disableNext: function(model, wizard) {
                            var disabled = false;
                            if(model && model.motiu){
                                disabled = true;
                            }
                            return !disabled;
                        },
                        exitValidation: function(model) {
                            var selected = false;

                            if(model && model.motiu){
                                selected = true;
                            }

                            return selected;
                        },
                        annexaFormly: {
                            fields: [
                                {
                                    key: 'motiu',
                                    type: 'annexaTextAreaRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    templateOptions: {
                                        type: 'text',
                                        required: true,
                                        focus: false,
                                        label: '',
                                        updateOnBlur:false
                                    }
                                }
                            ]
                        }
                    },
                    {
                        title: 'global.literals.profile_handler_finally',
                        key: 'deleteTransactionNewTram',
                        disabled: false,
                        disableNext: function(model, wizard) {
                            return true;
                        },
                        exitValidation: function(model) {
                            var selected = false;

                            if(model && model.motiu){
                                selected = true;
                            }

                            return selected;
                        },
                        annexaFormly: {
                            fields: [
                                {
                                    key: 'dossierTransactionType',
                                    type: 'annexaRadioCheckboxRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    templateOptions: {
                                        type: 'radio',
                                        optionsAttr: 'bs-options',
                                        required: true,
                                        validate: true,
                                        options:[],
                                        ngOptions:'option[to.valueProp] as option in to.options',
                                        valueProp: 'id',
                                        labelProp: 'value'
                                    },
                                    hideExpression:function($viewValue, $modelValue, scope) {
                                        if(scope.model.haveReopenPermission) {
                                            return false;
                                        }else {
                                            return true;
                                        }
                                    },
                                    controller: ['$scope', '$filter', function ($scope, $filter) {
                                        $scope.to.options = [{id:1, value:$filter("translate")("global.tram.literals.create_new_dossier_transaction")},{id:2, value :$filter("translate")("global.tram.literals.select_closed_dossier_transaction")}];
                                    }]
                                },
                                {
                                    key: 'profile',
                                    type: 'annexaLoadUserAndComboButtonRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    templateOptions: {
                                        type: 'text',
                                        label: '',
                                        required: false,
                                        focus: false,
                                        options: [],
                                        loadFunction: function () {},
                                        saveFunction: function () {},
                                        inputType: 'Profile',
                                        buttonLabel: 'global.literals.select',
                                        atributes: {},
                                        step: undefined,
                                        placeholder: 'global.reg.literals.profileOrUserName',
                                        showTitle: false,
                                        showOr: false
                                    },
                                    hideExpression:function($viewValue, $modelValue, scope) {
                                        if(scope.model.dossierTransactionType == 1 || !scope.model.haveReopenPermission) {
                                            return false;
                                        }else {
                                            return true;
                                        }
                                    }
                                },
                                {
                                    key: 'dossierTransactions',
                                    type: 'annexaSelectRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true,
                                        clear: function ($event, model, key, $select) {
                                            $event.stopPropagation();
                                            model[key] = undefined;
                                            if ($select) {
                                                $select.selected = undefined;
                                                $select.search = undefined;
                                            }
                                        }
                                    },
                                    templateOptions: {
                                        optionsAttr: 'bs-options',
                                        ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                        label: 'global.tram.literals.transactions',
                                        valueProp: 'id',
                                        labelProp: 'name',
                                        placeholder: '',
                                        options: []
                                    },
                                    validators: {
                                        "requiredOne": {
                                            "expression": function (viewValue, modelValue, scope) {
                                                var valid = true;

                                                return valid;
                                            }
                                        }
                                    },
                                    watcher: {
                                        type: '$watchCollection',
                                        expression: 'model',
                                        listener: function (field, _new) {
                                            if (field.formControl) {
                                                field.formControl.$validate();
                                            }
                                        }
                                    },
                                    hideExpression:function($viewValue, $modelValue, scope) {
                                        if(scope.model.dossierTransactionType == 2 && scope.model.haveReopenPermission) {
                                            return false;
                                        }else {
                                            return true;
                                        }
                                    },
                                    controller: ['$scope', 'Language', 'TramFactory', function ($scope, Language, TramFactory) {
                                        if(TramFactory.Dossier && TramFactory.Dossier.dossierTransactions){
                                            var transactions = $linq(TramFactory.Dossier.dossierTransactions).where("x => !x.actual").toArray();
                                            if(transactions) {
                                                $scope.to.options.length = 0;
                                                _.forEach(transactions, function(value) {
                                                    $scope.to.options.push({id: value.id, name: value[Language.getActiveColumn()]});
                                                });
                                            }
                                        }
                                    }]
                                }
                            ]
                        }
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        representationAdd: {
            title: 'global.literals.add_representation_modal_title',
            size: 'modal-sm',
            icon: 'fa fa-user',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'startValidityPeriod',
                                type: 'annexaDatepickerRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.startDate',
                                    required: true,
                                    validate: true,
                                    changeFunction: 'to.changeFunction',
                                    datepickerOptions: {
                                        format: 'dd/MM/yyyy',
                                        initDate: new Date(),
                                        showWeeks: false,
                                        startingDay: 1
                                    }
                                }
                            },
                            {
                                key: 'endValidityPeriod',
                                type: 'annexaDatepickerRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.endDate',
                                    required: true,
                                    validate: true,
                                    datepickerOptions: {
                                        format: 'dd/MM/yyyy',
                                        initDate: new Date(),
                                        showWeeks: false,
                                        startingDay: 1
                                    }
                                },
                                expressionProperties: {
                                    'validation.show': 'formControl.$submitted'
                                },
                                validators:{
                                    "dateRange": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(modelValue && modelValue < scope.model.startValidityPeriod){
                                                valid = false;
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            field.formControl.$validate();
                                        }
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        consentsAdd: {
            title: 'global.literals.newConsent',
            size: '',
            icon: 'fa fa-tag',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'thirdAddress',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.notificationChannel',
                                    valueProp: 'key',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false
                                },
                                controller:['$scope', 'Language', function($scope, Language) {
                                    var getAdressesHtml = function(contact){
                                        var thirdAddress = new ThirdAddress(undefined, Language.getActiveColumn());
                                        return thirdAddress.getAddressHtml(contact, false, true);
                                    }
                                    var contacts = [];

                                    var contactBoxContent = undefined;

                                    if(angular.element('#new-third').length != 0) {
                                        contactBoxContent = angular.element('#new-third').scope().$parent.entity.boxes[0].boxDefinition.content;
                                    } else if (angular.element('#edit-third').length != 0) {
                                        contactBoxContent = angular.element('#edit-third').scope().contactBox.boxDefinition.content;
                                    }

                                    _.forEach(contactBoxContent, function (contact, key) {
                                        contact.name = getAdressesHtml(contact);
                                        contacts.push(contact);
                                    });

                                    $scope.to.options = contacts;
                                }]
                            },
                            {
                                key: 'consentType',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed:true,
                                    row:true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.type',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [
                                        { id: 'ALL', name: 'global.literals.ALL' },
                                        { id: 'FAMILY_PROCEDURE', name: 'global.literals.FAMILY_PROCEDURE' },
                                        { id: 'PROCEDURE', name: 'global.literals.PROCEDURE' },
                                        { id: 'DOSSIER', name: 'global.literals.DOSSIER' }
                                    ],
                                    required: true,
                                    focus: false
                                },
                                hideExpression:function($viewValue, $modelValue, scope) {
                                    if(scope.model.thirdAddress) {
                                        return false;
                                    }

                                    return true;
                                }
                            },
                            {
                                key: 'familyProcedure',
                                type: 'annexaSelectTreeRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    label: 'global.literals.family',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', 'ThirdFactory', function($scope, ThirdFactory) {
                                    $scope.to.options = ThirdFactory.familyProceduresTree;
                                }],
                                hideExpression:function($viewValue, $modelValue, scope){
                                    if(scope.model.thirdAddress && scope.model.consentType && scope.model.consentType.id == 'FAMILY_PROCEDURE' ){
                                        return false;
                                    }

                                    return true;
                                }
                            },
                            {
                                key: 'procedure',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.literals.PROCEDURE',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false
                                },
                                controller:['$scope', 'ThirdFactory', 'Language', function($scope, ThirdFactory, Language) {
                                    $scope.to.options = ThirdFactory.procedures;
                                    $scope.to.labelProp = Language.getActiveColumn();
                                }],
                                hideExpression:function($viewValue, $modelValue, scope){
                                    if(scope.model.thirdAddress && scope.model.consentType && scope.model.consentType.id == 'PROCEDURE' ){
                                        return false;
                                    }

                                    return true;
                                }
                            },
                            {
                                key: 'dossier',
                                type: 'annexaFilterInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    label: 'global.literals.DOSSIER',
                                    placeholder: 'global.literals.placeholder_search_dossiers',
                                    search: function() {},
                                    advancedSearch: function() {},
                                    advancedSearchAdd: function(object, modal) {
                                        //TODO: Treure repetits
                                        var proccesResult = function (object) {
                                            return object.dossierNumber + " (" + object.subject + ")";
                                        };
                                        object.value = proccesResult(object.object);
                                        modal.annexaFormly.model.modal_body.dossier = object;
                                        this.close();
                                    }
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    if(scope.model.thirdAddress && scope.model.consentType && scope.model.consentType.id == 'DOSSIER' ){
                                        return false;
                                    }

                                    return true;
                                }
                            }
                        ]
                    }
                ]
            }
        },
        contactsAdd: {
            title: 'global.literals.add_contacts_modal_title',
            size: '',
            icon: 'fa fa-at',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'addressType',
                                type: 'annexaRadioRow',
                                className: 'col-sm-12 ',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12 b-b-lg-primary',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'radio',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: true,
                                    validate: true,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'addressType',
                                    labelProp: 'value',
                                    focus: true
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:1, value:$filter('translate')('global.thirds.addressTypesModal.POSTAL'), addressType:'POSTAL'});
                                    $scope.to.options.push({id:2, value:$filter('translate')('global.thirds.addressTypesModal.TELEMATIC_MOBILEPHONE'), addressType:'TELEMATIC_MOBILEPHONE'});
                                    $scope.to.options.push({id:3, value:$filter('translate')('global.thirds.addressTypesModal.TELEMATIC_EMAIL'), addressType:'TELEMATIC_EMAIL'});
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var hide = false;
                                    if(scope.model.id){
                                        hide = true;
                                    }
                                    return hide;
                                }
                            },
                            {
                                key: 'contactPrefix',
                                type: 'annexaInputRow',
                                className: 'col-sm-3',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.internationalPrefix',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'TELEMATIC_MOBILEPHONE' ){
                                        show = false;
                                    }
                                    return show;
                                },
                                validators:{
                                    "number": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(modelValue && isNaN(modelValue)){
                                                valid = false;
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            if (field.formControl.$validate) {
                                                field.formControl.$validate();
                                            } else {
                                                field.formControl[field.formControl.length - 1].$validate();
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                key: 'contactPhone',
                                type: 'annexaInputRow',
                                className: 'col-sm-9',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.profile.phone',
                                    required: true,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'TELEMATIC_MOBILEPHONE' ){
                                        show = false;
                                    }
                                    return show;
                                },
                                validators:{
                                    "number": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            var valid = true;
                                            if(modelValue && isNaN(modelValue)){
                                                valid = false;
                                            }
                                            return valid;
                                        }
                                    }
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model',
                                    listener: function(field, _new) {
                                        if(field.formControl){
                                            if (field.formControl.$validate) {
                                                field.formControl.$validate();
                                            } else {
                                                field.formControl[field.formControl.length - 1].$validate();
                                            }
                                        }
                                    }
                                }
                            },
                            {
                                key: 'contactEmail',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'email',
                                    label: 'global.profile.email',
                                    required: true,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'TELEMATIC_EMAIL' ){
                                        show = false;
                                    }
                                    return show;
                                }
                            },
                            {
                                key: 'country',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed:true,
                                    row:true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.country',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyCountryEvent', { item: $item });
                                    }
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL'){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($scope, Language, RestService) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    RestService.findAll('StreetCountry')
                                        .then(function(data) {
                                            $scope.to.options = JSOG.decode(data.data);
                                            if($scope.to.options && $scope.model.country && $scope.model.country.id) {
                                                var countries = $linq($scope.to.options).singleOrDefault(undefined, "x => x.id == " + $scope.model.country.id);
                                                if (countries) {
                                                    $scope.model.country = countries;
                                                }
                                            }
                                        }).catch(function(error) {
                                            console.error(error);
                                    });
                                }
                            },
                            {
                                key: 'validState',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    row: true,
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: '',
                                    label: 'global.thirds.literals.state',
                                    valueProp: 'id',
                                    labelProp: '',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyStateEvent', { item: $item });
                                    }
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' && scope.model.hasStates){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($q, $scope, Language, RestService, $rootScope) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.ngOptions = 'option in to.options | filter: {'+$scope.to.labelProp+': $select.search}';
                                    var calculateStates = function(){
                                        var statesAux = [];
                                        RestService.findAll('StreetState').then(function(data) {
                                            $scope.to.allStates = JSOG.decode(data.data);
                                            if($scope.model && $scope.model.country && $scope.model.country.id){
                                                statesAux = $.grep($scope.to.allStates, function (n, j) {if (n.country.id == $scope.model.country.id) {return n;}return;});
                                            }
                                            if(statesAux.length > 0){
                                                $scope.to.options = statesAux;
                                                $scope.model.hasStates = true;
                                                $scope.model.hasCities = true;
                                                $scope.model.hasStreets = true;
                                            }else{
                                                $scope.model.hasStates = false;
                                                $scope.model.hasCities = false;
                                                $scope.model.hasStreets = false;
                                                $scope.to.options = [];
                                            }
                                        }).catch(function(error) {
                                            $scope.to.allStates = [];
                                            $scope.to.options = [];
                                            $scope.model.hasStates = false;
                                            $scope.model.hasCities = false;
                                            $scope.model.hasStreets = false;
                                        });
                                    }
                                    if($scope.model && $scope.model.country && $scope.model.country.id){
                                        calculateStates();
                                    }
                                    $rootScope.$on('modifyCountryEvent', function(event, args) {
                                        $scope.model.validState = undefined;
                                        if($scope.to.allStates && $scope.to.allStates.length > 0) {
                                            var statesAux = [];
                                            statesAux = $.grep($scope.to.allStates, function (n, j) {if (n.country.id == $scope.model.country.id) {return n;}return;});
                                            if(statesAux.length > 0){
                                                $scope.to.options = statesAux;
                                                $scope.model.hasStates = true;
                                                $scope.model.hasCities = true;
                                                $scope.model.hasStreets = true;
                                            }else{
                                                $scope.model.hasStates = false;
                                                $scope.model.hasCities = false;
                                                $scope.model.hasStreets = false;
                                                $scope.to.options = [];
                                            }
                                        }else{
                                            calculateStates();
                                        }
                                    });
                                }

                            },
                            {
                                key: 'stateAddress',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: '',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.state',
                                    required: true,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' && !scope.model.hasStates){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($q, $scope, Language, RestService, $rootScope) {
                                    $rootScope.$on('modifyCountryEvent', function(event, args) {
                                        $scope.model.stateAddress = undefined;
                                    });
                                }
                            },
                            {
                                key: 'validCity',
                                type: 'annexaSelectRow',
                                className: 'col-sm-9 daughter-label-strong',
                                data: {
                                    row: true,
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: '',
                                    label: 'global.literals.city',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyCityEvent', { item: $item });
                                    }
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' && scope.model.hasCities){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($q, $scope, ThirdFactory, $rootScope) {
                                    $scope.to.ngOptions = 'option in to.options | filter: {'+$scope.to.labelProp+': $select.search}';
                                    var calculateCities = function(){
                                        ThirdFactory.getCitiesByState($scope.model.validState.id).then(function (data) {
                                            var citiesAux = JSOG.decode(data);
                                            citiesAux = new SortedArray(citiesAux, 'name').sort();
                                            if (citiesAux.length > 0) {
                                                $scope.to.options = citiesAux;
                                                $scope.model.hasCities = true;
                                                $scope.model.hasStreets = true;
                                            } else {
                                                $scope.to.options = [];
                                                $scope.model.hasCities = false;
                                                $scope.model.hasStreets = false;
                                            }
                                        }).catch(function (error) {
                                            $scope.to.options = [];
                                            $scope.model.hasCities = false;
                                            $scope.model.hasStreets = false;
                                        });
                                    }
                                    if($scope.model && $scope.model.validState && $scope.model.validState.id){
                                        calculateCities();
                                    }
                                    $rootScope.$on('modifyStateEvent', function(event, args) {
                                        $scope.model.validCity = undefined;
                                        if($scope.model.validState && $scope.model.validState.id) {
                                            calculateCities();
                                        }else{
                                            $scope.to.options = [];
                                            $scope.model.hasCities = false;
                                            $scope.model.hasStreets = false;
                                        }
                                    });

                                    $rootScope.$on('modifyCountryEvent', function(event, args) {
                                        $scope.model.validCity = undefined;
                                        $scope.to.options = [];
                                    });
                                }
                            },
                            {
                                key: 'city',
                                type: 'annexaInputRow',
                                className: 'col-sm-9',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.city',
                                    required: true,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL'&& !scope.model.hasCities){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($q, $scope, Language, RestService, $rootScope) {
                                    $rootScope.$on('modifyStateEvent', function(event, args) {
                                        $scope.model.city = undefined;
                                    });

                                    $rootScope.$on('modifyCountryEvent', function(event, args) {
                                        $scope.model.city = undefined;
                                    });
                                }
                            },
                            {
                                key: 'postalCode',
                                type: 'annexaInputRow',
                                className: 'col-sm-3',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.postalCode',
                                    required: true,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' ){
                                        show = false;
                                    }
                                    return show;
                                }
                            },
                            {
                                key: 'streetType',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    row: true,
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option in to.options | filter: $select.search',
                                    label: 'global.thirds.literals.streetType',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' && scope.model.hasStreetTypes){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($q,$scope, Language, RestService, $rootScope) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.streetTypeAux = [];
                                    RestService.findAll('StreetStreetType').then(function (data) {
                                        var streetTypeAux = JSOG.decode(data.data);
                                        if (streetTypeAux.length > 0) {
                                            $scope.to.options = streetTypeAux;
                                            $scope.model.hasStreetTypes = true;
                                        } else {
                                            $scope.to.options = [];
                                            $scope.model.hasStreetTypes = false;
                                        }
                                    }).catch(function (error) {
                                        $scope.model.hasStreetTypes = false;
                                        $scope.to.options = [];
                                    });
                                    $rootScope.$on('modifyStreetEvent', function (event, args) {
                                        if($scope.model.validStreet && $scope.model.validStreet.streetType &&  $scope.model.validStreet.streetType.id) {
                                            $scope.model.streetType = $scope.model.validStreet.streetType;
                                        }
                                    });
                                }
                            },
                            {
                                key: 'validStreet',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    row: true,
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: '',
                                    label: 'global.thirds.literals.streetName',
                                    valueProp: 'id',
                                    labelProp: 'name',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    focus: false,
                                    onSelected: function($item) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifyStreetEvent', { item: $item });
                                    }
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' && scope.model.hasStreets){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($q, $scope, ThirdFactory, $rootScope) {
                                    $scope.to.ngOptions = 'option in to.options | filter: {' + $scope.to.labelProp + ': $select.search}';
                                    var calculateStreet = function(){
                                        ThirdFactory.getStreetsByCity($scope.model.validCity.id).then(function (data) {
                                            var streetsAux = JSOG.decode(data);
                                            streetsAux = new SortedArray(streetsAux, 'name').sort();
                                            if (streetsAux.length > 0) {
                                                $scope.to.options = streetsAux;
                                                $scope.model.hasStreets = true;
                                            } else {
                                                $scope.to.options = [];
                                                $scope.model.hasStreets = false;
                                            }
                                        }).catch(function (error) {
                                            $scope.to.options = [];
                                            $scope.model.hasStreets = false;
                                        });
                                    }

                                    if($scope.model && $scope.model.validCity && $scope.model.validCity.id){
                                        calculateStreet();
                                    }

                                    $rootScope.$on('modifyCityEvent', function (event, args) {
                                        $scope.model.validStreet = undefined;
                                        if ($scope.model.validState && $scope.model.validState.id && $scope.model.validCity && $scope.model.validCity.id) {
                                            calculateStreet();
                                        } else {
                                            $scope.to.options = [];
                                            $scope.model.hasStreets = false;
                                        }
                                    });

                                    $rootScope.$on('modifyCountryEvent', function (event, args) {
                                        $scope.model.validStreet = undefined;
                                        $scope.to.options = [];
                                    });

                                    $rootScope.$on('modifyStateEvent', function (event, args) {
                                        $scope.model.validStreet = undefined;
                                        $scope.to.options = [];
                                    });
                                }
                            },
                            {
                                key: 'street',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.streetName',
                                    required: true,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL'&& !scope.model.hasStreets){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($q, $scope, Language, RestService, $rootScope) {
                                    $rootScope.$on('modifyCityEvent', function(event, args) {
                                        $scope.model.street = undefined;
                                    });

                                    $rootScope.$on('modifyStateEvent', function(event, args) {
                                        $scope.model.street = undefined;
                                    });

                                    $rootScope.$on('modifyCountryEvent', function(event, args) {
                                        $scope.model.street = undefined;
                                    });
                                }
                            },
                            //TODO: FALTA TOT EL TEMA DEL validStreet
                            {
                                key: 'numberAddres',
                                type: 'annexaInputRow',
                                className: 'col-sm-20',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.number',
                                    required: true,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' ){
                                        show = false;
                                    }
                                    return show;
                                }
                            },
                            {
                                key: 'block',
                                type: 'annexaInputRow',
                                className: 'col-sm-20',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.block',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' ){
                                        show = false;
                                    }
                                    return show;
                                }
                            },
                            {
                                key: 'stair',
                                type: 'annexaInputRow',
                                className: 'col-sm-20',
                                data: {
                                    row: true,
                                    colClass: '',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.stair',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' ){
                                        show = false;
                                    }
                                    return show;
                                }
                            },
                            {
                                key: 'floor',
                                type: 'annexaInputRow',
                                className: 'col-sm-20',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.floor',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' ){
                                        show = false;
                                    }
                                    return show;
                                }
                            },
                            {
                                key: 'door',
                                type: 'annexaInputRow',
                                className: 'col-sm-20',
                                data: {
                                    row: true,
                                    colClass: ' ',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.thirds.literals.door',
                                    required: false,
                                    focus: false,
                                    updateOnBlur:true
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' ){
                                        show = false;
                                    }
                                    return show;
                                }
                            },
                            {
                                key: 'defaultEmail',
                                type: 'annexaRadioRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'checkbox',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: true,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'defaultType',
                                    labelProp: 'value'
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'TELEMATIC_EMAIL' && scope.model.showDefaultByTypeEmail) {
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:1, value:$filter('translate')('global.thirds.literals.favoriteByType'), defaultType:'defaultByType'});
                                }
                            },
                            {
                                key: 'defaultPostal',
                                type: 'annexaRadioRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'checkbox',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: true,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'defaultType',
                                    labelProp: 'value'
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'POSTAL' && scope.model.showDefaultByTypePostal){
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:1, value:$filter('translate')('global.thirds.literals.favoriteByType'), defaultType:'defaultByType'});
                                }
                            },
                            {
                                key: 'defaultNumber',
                                type: 'annexaRadioRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function($event,model,key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'checkbox',
                                    radioClass: 'radio-inline',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: true,
                                    options:[],
                                    ngOptions:'option[to.valueProp] as option in to.options',
                                    valueProp: 'defaultType',
                                    labelProp: 'value'
                                },
                                hideExpression:function($viewValue, $modelValue, scope){
                                    var show = true;
                                    if(scope.model.addressType == 'TELEMATIC_MOBILEPHONE' && scope.model.showDefaultByTypeMobile) {
                                        show = false;
                                    }
                                    return show;
                                },
                                controller: function ($scope, $filter) {
                                    $scope.to.options = [];
                                    $scope.to.options.push({id:1, value:$filter('translate')('global.thirds.literals.favoriteByType'), defaultType:'defaultByType'});
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        uploadFile: {
            title: '',
            size: '',
            icon: 'fa fa-file-text-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'file',
                                type: 'annexaFileUpload',
                                templateOptions: {
                                    label: 'global.literals.filename',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    accept: ''
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        uploadMultiFile: {
            title: '',
            size: '',
            icon: 'fa fa-file-text-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'file',
                                type: 'annexaMultiFileUpload',
                                templateOptions: {
                                    label: 'global.literals.filename',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    accept: ''
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        uploadFileBase64: {
            title: '',
            size: '',
            icon: 'fa fa-file-text-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'file',
                                type: 'annexaFileUploadBase64',
                                templateOptions: {
                                    label: 'global.literals.filename',
                                    required: true
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    accept: ''
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        documentNew:{
            title: 'global.literals.modalNewDocumentTitle',
            size: '',
            icon: '&#xe89c',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body ',
                        fieldGroup: [
                            {
                                key: 'typeSelect',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | languagefilter: $select.search',
                                    label: 'global.literals.type',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    clearHide: true,
                                    onSelected: function(item, options) {
                                        var $rootScope = angular.element(document.body).injector().get('$rootScope');

                                        $rootScope.$broadcast('newDocumentTypeSelected', { item: item });
                                    }
                                },
                                controller: ['$scope', '$rootScope', 'DccumentsFactory', 'Language', 'GlobalDataFactory', function($scope, $rootScope, DccumentsFactory, Language, GlobalDataFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = [];
                                    var types = GlobalDataFactory.documentTypes;
                                    if($scope.to.optionsAux && $scope.to.optionsAux.length > 0){
                                    	types = $scope.to.optionsAux;
                                    }
                                    _.forEach (types, function(docType){
                                        if(docType.profiles && docType.profiles.length > 0){
                                            // intersection de perfil de usuario y los perfiles de documentTypeProfile
                                            var profiles = $linq(docType.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                                                if(y.profile){
                                                    return (x.profile.id == y.profile.id);
                                                }
                                                // en este caso es por si hubiera un null, ya que admitimos todos los perfiles.
                                                else{
                                                    return true;
                                                }
                                            }).toArray();
                                            if(profiles && profiles.length > 0){
                                                $scope.to.options.push(angular.copy(docType));
                                            }
                                            else{
                                            }
                                        }
                                        else{
                                            $scope.to.options.push(angular.copy(docType));
                                        }
                                    });

                                    $scope.$on('newDocumentTypeSelected', function(event, args) {
                                        if(args.item) {
                                        	if($scope.fields && $scope.fields.length > 0 && $scope.fields[0].templateOptions && $scope.fields[0].templateOptions.optionsAux){
                                            	DccumentsFactory.typeSelected(args.item, $scope.model, $scope.fields, undefined, $scope.fields[0].templateOptions.optionsAux);
                                            }else{
                                            	DccumentsFactory.typeSelected(args.item, $scope.model, $scope.fields);
                                            }
                                        }
                                    });
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'template',
                                type: 'annexaDocumentFileTemplate',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    labelFile: 'global.literals.filename',
                                    labelTemplate: 'global.literals.template',
                                    options: [],
                                    onlyLastLevelClick: true,
                                    required: false,
                                    keyFile:'file',
                                    keyTemplate:'template',
                                    inputType:'inputType',
                                    onSelectedTemplate: function(item, options) {
                                        var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                        DccumentsFactory.selectTemplate(this.$parent.$parent.$parent.model, item);
                                    },
                                    onSelectedFile: function(element){
                                    	var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                        $rootScope.$broadcast('modifySelectedFile', { element: element });
                                    }
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                    return DccumentsFactory.hideDocumentTemplate(scope.model);
                                },
                                validators: {
                                    "required": {
                                        "expression": function (viewValue, modelValue, scope) {
                                            //var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                            var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                            return DccumentsFactory.requiredValidator(scope.model,  scope.fields[3]);
                                        }
                                    }
                                },
                                watcher: [
                                    {
                                        type: '$watchCollection',
                                        expression: 'model.typeSelect',
                                        listener: function(field, _new, aux,scope) {
                                            var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                            field.templateOptions.options = DccumentsFactory.getTemplateOptions(scope.model);
                                            scope.$broadcast('treeTemplatesChange');
                                            if(field.formControl){
                                                field.formControl.$validate();
                                            }
                                        }
                                    },
                                    {
                                        type: '$watchCollection',
                                        expression: 'model.file',
                                        listener: function(field, newValue, oldValue, scope, stopWatching) {
                                            if(newValue !== oldValue) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        }
                                    },
                                    {
                                        type: '$watchCollection',
                                        expression: 'model.template',
                                        listener: function(field, newValue, oldValue, scope, stopWatching) {
                                            if(newValue !== oldValue) {
                                                if(field.formControl){
                                                    field.formControl.$validate();
                                                }
                                            }
                                        }
                                    }
                                ],
                                controller: ['$scope', '$rootScope', 'DccumentsFactory', 'Language', 'CommonService', 'GlobalDataFactory', function($scope, $rootScope, DccumentsFactory, Language, CommonService, GlobalDataFactory) {
                                    if($scope.fields && $scope.fields.length > 0 && $scope.fields[0].templateOptions && $scope.fields[0].templateOptions.optionsAux){
                                    	DccumentsFactory.typeSelected({id: $scope.model.typeSelect}, $scope.model, undefined, undefined, $scope.fields[0].templateOptions.optionsAux);
                                    }else{
                                    	DccumentsFactory.typeSelected({id: $scope.model.typeSelect}, $scope.model);
                                    }
                                    DccumentsFactory.setFileArchiveClassification($scope.model);
                                    
                                    $scope.$on('modifySelectedFile', function(event, args) {
                                    	DccumentsFactory.selectFile($scope.model, args.element.files);
                                    });
                                }]
                            },
                            {
                                key: 'title',
                                type: 'annexaInputRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.title',
                                    required: false,
                                    focus: false,
                                    placeholder:'',
                                    updateOnBlur: true,
                                    maxlength:200
                                },
                                controller: ['$scope', 'DccumentsFactory', 'Language', '$rootScope', 'GlobalDataFactory', function($scope, DccumentsFactory, Language, $rootScope, GlobalDataFactory) {
                                    var titleAux = '';
                                    if($scope.model.typeSelect) {
                                        var selectedType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.typeSelect);

                                        if(selectedType) {
                                            $scope.to.placeholder = selectedType[Language.getActiveColumn()];
                                        } else {
                                            $scope.to.placeholder = '';
                                        }
                                    } else {
                                        $scope.to.placeholder = '';
                                    }

                                    $scope.to.onFocus = function(viewValue){
                                        if($rootScope && ($rootScope.esetMode || ($rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.validate_title_document && $rootScope.app.configuration.validate_title_document.value))){
                                            if($scope.model.typeSelect) {
                                                var selectedType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.typeSelect);
                                                if(selectedType) {                                                	
                                                	if ($scope.lastSelectedType) {
                                                		//Ya hay un tipo de documento seleccionado
                                                		var pos = $scope.model.title.indexOf($scope.lastSelectedType);
                                                		if (pos >= 0) {
                                                			//Encuentra el acronimo del tipo de documento anterior, lo reemplaza por el nuevo acronimo
                                                			$scope.model.title = $scope.model.title.replace($scope.lastSelectedType, selectedType.acronym);
                                                		} else {
                                                			//No encuentra el acronimo del tipo de documento anterior, pone el nuevo acronimo al principio
                                                			$scope.model.title = selectedType.acronym + ' ' + $scope.model.title;
                                                		}                                                		                                                		
                                                	} else {
                                                		//No hay un tipo de documento seleccionado
                                                		if (viewValue) {
                                                			if (viewValue.startsWith(selectedType.acronym + ' ')) {
                                                				$scope.model.title = viewValue;
                                                			} else {
                                                				$scope.model.title = selectedType.acronym + ' ' + viewValue;
                                                			}
                                                		} else {
                                                			$scope.model.title = selectedType.acronym + ' ';
	                                                    }
                                                	}                                                	
                                                	titleAux = angular.copy($scope.model.title);
                                                	$scope.lastSelectedType = selectedType.acronym;
                                                }                                                
                                            }
                                        }
                                    };

                                }],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    if(scope.model.wizard_AllInOne) {
                                        return true;
                                    }else{
                                        return false;
                                    }
                                }
                            },
                            {
                                key: 'dateDocumentCreation',
                                type: 'annexaDatepickerRow',
                                className: 'col-sm-12',
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    informed: true,
                                    clear: function ($event, model, key, $select) {
                                        $event.stopPropagation();
                                        model[key] = undefined;
                                        if ($select) {
                                            $select.selected = undefined;
                                            $select.search = undefined;
                                        }
                                    }
                                },
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.dateDocumentCreation',
                                    required: true,
                                    validate: true,
                                    changeFunction: 'to.changeFunction',
                                    datepickerOptions: {
                                        format: 'dd/MM/yyyy',
                                        initDate: new Date(),
                                        showWeeks: false,
                                        startingDay: 1
                                    }
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    return !scope.model.add_document_type_and_creation_date_to_document_name;
                                }
                            },
                            {
                                key: 'archiveClassification',
                                type: 'annexaSelectTreeRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    label: 'global.literals.classificationBox',
                                    options: [],
                                    required: false,
                                    focus: false
                                },
                                controller:['$scope', 'CommonService', 'DccumentsFactory', 'Language', 'GlobalDataFactory', function($scope, CommonService, DccumentsFactory, Language, GlobalDataFactory) {
                                    $scope.to.options = CommonService.getTreeData(GlobalDataFactory.archiveClassifications, Language.getActiveColumn());
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.onSelected = function(index) {
                                        $scope.model.modifyArchiveClassification = true;
                                    };
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                watcher:{
                                    type: '$watchCollection',
                                    expression: 'model.typeSelect',
                                    listener: function(field, _new, aux,scope) {
                                        var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                        DccumentsFactory.setFileArchiveClassification(scope.model);
                                    }
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    var DccumentsFactory = angular.element(document.body).injector().get('DccumentsFactory');
                                    return DccumentsFactory.hideArchiveClassification(scope.model);
                                }
                            },
                            {
                                key: 'section',
                                type: 'annexaSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.section',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: [],
                                    required: true,
                                    clearHide: true
                                },
                                controller: ['$scope', 'Language', 'GlobalDataFactory', 'DccumentsFactory', function($scope, Language, GlobalDataFactory, DccumentsFactory) {
                                    $scope.to.options = DccumentsFactory.sectionsUser;
                                    $scope.to.labelProp = Language.getActiveColumn();
                                }],
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    return scope.model.oneSection ? true : false;
                                }
                            },
                            {
                                key: 'profiles',
                                type: 'annexaMultipleSelectRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    label: 'global.literals.profiles',
                                    valueProp: 'id',
                                    labelProp: 'completeName',
                                    placeholder: '',
                                    options: [],
                                    required: true
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                },
                                controller:['$scope', 'Language', 'GlobalDataFactory', 'DccumentsFactory', function($scope, Language, GlobalDataFactory, DccumentsFactory) {
                                    $scope.to.labelProp = Language.getActiveColumn();
                                    $scope.to.options = $scope.model.module != 'EXPED' ? DccumentsFactory.profilesUser :
                                        $linq(DccumentsFactory.profilesUser).intersect($linq(JSOG.decode($scope.model.extra).processProfiles).select("x => x.profile").toArray(), "(x, y) => x.id == y.id").toArray();

                                    if($scope.to.options && $scope.to.options.length == 1){
                                        if($scope.model.profiles){
                                            $scope.model.profiles.push($scope.to.options[0].id);
                                        }
                                        else{
                                            $scope.model.profiles = [];
                                            $scope.model.profiles.push($scope.to.options[0].id);
                                        }
                                    }else if($scope.model.module == 'EXPED'){
                                        if(!$scope.model.profiles){
                                            $scope.model.profiles = [];
                                        }else{
                                            $scope.model.profiles.length = 0;
                                        }
                                    }
                                }],
                                hideExpression: function ($viewValue, $modelValue, scope) {
                                    return scope.model.oneProfile ? true : false;
                                }

                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        generateFoliate: {
            title: 'global.literals.generate_foliate',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'selectAll',
                                type: 'annexaSelectAllRow',
                                className: 'col-sm-12 p-t-0 p-b-0',
                                templateOptions: {
                                    type: 'checkbox',
                                    label: 'global.bpm.selectAllDocs',
                                    validate: false,
                                    required: false,
                                    changeValue: function(){}
                                },
                                controller:['$scope', function($scope) {
                                    $scope.to.changeValue = function(){
                                        if($scope.model.documents){
                                        	var keys = Object.keys($scope.model.documents);
                                            if(keys && keys.length > 0){
	                                            _.forEach(keys, function(key){
	                                                if($scope.model.selectAll){
	                                                    $scope.model.documents[key] = true;
	                                                }else{
	                                                    $scope.model.documents[key] = false;
	                                                };
	                                            });
	                                        }else{
	                                        	$scope.model.selectAll = false;
	                                        }
                                        }else{
                                        	$scope.model.selectAll = false;
                                        }
                                    };
                                }],
                                hideExpression: function($viewValue, $modelValue, scope) {
                                    var keys = Object.keys(scope.model.documents);
                                    if(keys && keys.length < 2){
                                        return true;
                                    }
                                    return false;
                                }
                            },
                            {
                                key: 'selectAllDocuments',
                                type: 'annexaSelectAllRow',
                                className: 'col-sm-3 p-t-0 p-b-0',
                                templateOptions: {
                                    type: 'checkbox',
                                    label: 'global.bpm.selectAllDocuments',
                                    validate: false,
                                    required: false,
                                    changeValue: function(){}
                                },
                                controller:['$scope', function($scope) {
                                    $scope.to.changeValue = function(){
                                        if($scope.model.documents){
                                        	var keys = $linq($scope.fields[4].templateOptions.options).where("x => x.type == 'DOCUMENT'").select("x => x.id").toArray();
                                            if(keys && keys.length > 0){
	                                            _.forEach(keys, function(key){
	                                                if($scope.model.selectAllDocuments){
	                                                    $scope.model.documents[key] = true;
	                                                }else{
	                                                    $scope.model.documents[key] = false;
	                                                };
	                                            });
	                                        }else{
	                                        	$scope.model.selectAllDocuments = false;
	                                        }
                                        }else{
                                        	$scope.model.selectAllDocuments = false;
                                        }
                                    };
                                }],
                                hideExpression: function($viewValue, $modelValue, scope) {
                                    var keys = Object.keys(scope.model.documents);
                                    if(keys && keys.length < 2){
                                        return true;
                                    }
                                    return false;
                                }
                            },
                            {
                                key: 'selectAllWorkDocuments',
                                type: 'annexaSelectAllRow',
                                className: 'col-sm-5 p-t-0 p-b-0',
                                templateOptions: {
                                    type: 'checkbox',
                                    label: 'global.bpm.selectAllWorkDocuments',
                                    validate: false,
                                    required: false,
                                    changeValue: function(){}
                                },
                                controller:['$scope', function($scope) {
                                    $scope.to.changeValue = function(){
                                        if($scope.model.documents){
                                        	var keys = $linq($scope.fields[4].templateOptions.options).where("x => x.type == 'WORK_DOCUMENT'").select("x => x.id").toArray();
                                            if(keys && keys.length > 0){
	                                            _.forEach(keys, function(key){
	                                                if($scope.model.selectAllWorkDocuments){
	                                                    $scope.model.documents[key] = true;
	                                                }else{
	                                                    $scope.model.documents[key] = false;
	                                                };
	                                            });
	                                        }else{
	                                        	$scope.model.selectAllWorkDocuments = false;
	                                        }
                                        }else{
                                        	$scope.model.selectAllWorkDocuments = false;
                                        }
                                    };
                                }],
                                hideExpression: function($viewValue, $modelValue, scope) {
                                    var keys = Object.keys(scope.model.documents);
                                    if(keys && keys.length < 2){
                                        return true;
                                    }
                                    return false;
                                }
                            },
                            {
                                key: 'selectAllInternalDocument',
                                type: 'annexaSelectAllRow',
                                className: 'col-sm-4 p-t-0 p-b-0',
                                templateOptions: {
                                    type: 'checkbox',
                                    label: 'global.bpm.selectAllInternalDocument',
                                    validate: false,
                                    required: false,
                                    changeValue: function(){}
                                },
                                controller:['$scope', function($scope) {
                                    $scope.to.changeValue = function(){
                                        if($scope.model.documents){
                                        	var keys = $linq($scope.fields[4].templateOptions.options).where("x => x.type == 'INTERNAL_DOCUMENT'").select("x => x.id").toArray();
                                            if(keys && keys.length > 0){
		                                    	_.forEach(keys, function(key){
		                                            if($scope.model.selectAllInternalDocument){
		                                                $scope.model.documents[key] = true;
		                                            }else{
		                                                $scope.model.documents[key] = false;
		                                            };
		                                        });
                                            }else{
                                            	$scope.model.selectAllInternalDocument = false;
                                            }
                                        }else{
                                        	$scope.model.selectAllInternalDocument = false;
                                        }
                                    };
                                }],
                                hideExpression: function($viewValue, $modelValue, scope) {
                                    var keys = Object.keys(scope.model.documents);
                                    if(keys && keys.length < 2){
                                        return true;
                                    }
                                    return false;
                                }
                            },
                            {
                                key: 'documents',
                                type: 'annexaRadioCheckboxRow',
                                className: 'col-sm-12 daughter-label-strong',
                                data: {
                                    informed: true,
                                    row: true
                                },
                                templateOptions: {
                                    type: 'checkHTMLOneModel',
                                    optionsAttr: 'bs-options',
                                    required: false,
                                    validate: false,
                                    options: [],
                                    ngOptions: 'option[to.valueProp] as option in to.options',
                                    valueProp: 'id',
                                    labelProp: 'value',
                                    labelHtml: 'typeName'
                                },
                                watcher: {
                                    type: '$watchCollection',
                                    expression: 'model.documents',
                                    listener: function (field, _new, _old, scope) {
                                        var keys = Object.keys(_new);
                                        var isAllTrue = true;
                                        if (keys && keys.length > 0) {
                                            _.forEach(keys, function (key) {
                                                isAllTrue = isAllTrue && (_new[key]) ? true : false;
                                            });
                                        } else {
                                            isAllTrue = false;
                                        }
                                        scope.model.selectAll = isAllTrue;
                                        keys = $linq(scope.fields[4].templateOptions.options).where("x => x.type == 'DOCUMENT'").select("x => x.id").toArray();
                                        var isAllDocument = true;
                                        if (keys && keys.length > 0) {
                                            _.forEach(keys, function (key) {
                                            	isAllDocument = isAllDocument && (_new[key]) ? true : false;
                                            });
                                        } else {
                                        	isAllDocument = false;
                                        }
                                        scope.model.selectAllDocuments = isAllDocument;
                                        keys = $linq(scope.fields[4].templateOptions.options).where("x => x.type == 'WORK_DOCUMENT'").select("x => x.id").toArray();
                                        var isAllWorkDocument = true;
                                        if (keys && keys.length > 0) {
                                            _.forEach(keys, function (key) {
                                            	isAllWorkDocument = isAllWorkDocument && (_new[key]) ? true : false;
                                            });
                                        } else {
                                        	isAllWorkDocument = false;
                                        }
                                        scope.model.selectAllWorkDocuments = isAllWorkDocument;
                                        keys = $linq(scope.fields[4].templateOptions.options).where("x => x.type == 'INTERNAL_DOCUMENT'").select("x => x.id").toArray();
                                        var isAllInternalDocument = true;
                                        if (keys && keys.length > 0) {
                                            _.forEach(keys, function (key) {
                                            	isAllInternalDocument = isAllInternalDocument && (_new[key]) ? true : false;
                                            });
                                        } else {
                                        	isAllInternalDocument = false;
                                        }
                                        scope.model.selectAllInternalDocument = isAllInternalDocument; 
                                    }
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        notificationThirdChangeStateModify: {
            title: 'global.literals.changeStateModify',
            size: '',
            icon: 'fa fa-check-square-o',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-label-value label="global.literals.state" value="modal.data.status[modal.languageColumn]"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field child-class="m-x-0" is-read-only="true" type="date" label="global.literals.date" model-value="modal.data.createdDate" model-property="createdDate" save="modal.saveChangeStateModify(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field child-class="m-x-0" is-read-only="true" type="time" label="global.literals.time" model-value="modal.data.createdTime" model-property="createdTime" save="modal.saveChangeStateModify(val, prop)" required="true" blur="ignore"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field child-class="m-x-0" is-read-only="true" type="textarea" label="global.literals.observations" model-value="modal.data.observations" model-property="observations" save="modal.saveChangeStateModify(val, prop)" required="true" blur="ignore" maxlength="500" rows="3"></annexa-editable-field>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-label-value label="global.literals.evidences" type="base64FileList" value="modal.data.evidences" value-property="name" delete-func="modal.deleteEvidence"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <div class="row">',
                '           <div class="col-xs-12">',
                '               <span class="btn btn-file btn-sm grey text-white">',
                '                   <span><i class="fa fa-plus" aria-hidden="true"></i> {{\'global.literals.addEvidences\' | translate}}</span>',
                '                   <input type="file" multiple class="form-control" id="newEvidences" name="newEvidences" onchange="angular.element(this).scope().modal.addEvidences(this)">',
                '               </span>',
                '           </div>',
                '       </div>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        notificationThirdChangeState: {
            title: 'global.literals.changeState',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'notificationThird',
                                type: 'annexaHidden',
                                className: '',
                                templateOptions: {
                                    type: 'hidden',
                                    label: '',
                                    required: false
                                }
                            },
                            {
                                key: 'notificationType',
                                type: 'annexaHidden',
                                className: '',
                                templateOptions: {
                                    type: 'hidden',
                                    label: '',
                                    required: false
                                }
                            },
                            {
                                key: 'status',
                                type: 'annexaSelectRow',
                                className: 'col-sm-6',
                                templateOptions: {
                                    required: true,
                                    focus: true,
                                    label: 'global.literals.state',
                                    optionsAttr: 'bs-options',
                                    ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                    valueProp: 'id',
                                    labelProp: 'language1',
                                    placeholder: '',
                                    options: []
                                },
                                controller: ['$scope', 'Language', function($scope, Language) {
                                    $scope.to.labelProp = Language.getActiveColumn();

                                    var usedStatuses = $linq($scope.model.notificationThird.statuses).select("x => x.status").toArray();

                                    var allowedStatuses = $linq($scope.model.notificationType.statuses).except(usedStatuses, "(x,y) => x.id == y.id").orderBy("x => x.orderView").toArray();

                                    $scope.to.options = allowedStatuses;
                                }],
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'createdDate',
                                type: 'annexaDatepickerRow',
                                className: 'col-sm-3',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.date',
                                    required: true,
                                    validate: true,
                                    changeFunction: 'to.changeFunction',
                                    datepickerOptions: {
                                        format: 'dd/MM/yyyy',
                                        initDate: new Date(),
                                        showWeeks: false,
                                        startingDay: 1,
                                        maxDate: new Date()
                                    }
                                }
                            },
                            {
                                key: 'createdTime',
                                type: 'annexaTimepickerRow',
                                className: 'col-sm-3',
                                templateOptions: {
                                    label: 'global.literals.time',
                                    required: true,
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ],
                        wrapper: 'annexaRow'
                    },
                    {
                        key: 'row2',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'observations',
                                type: 'annexaTextAreaRow',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.observations',
                                    required: false
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }

                        ]
                    },
                    {
                        key: 'row3',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'thirds',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-label-value'
                                },
                                data: {
                                    title: 'global.literals.evidences',
                                    value: [],
                                    type: 'base64FileList',
                                    rowClass: 'm-b-xs row',
                                    labelStrong: true
                                }
                            },
                            {
                                key: 'evidences',
                                type: 'annexaFileUploadBase64',
                                templateOptions: {
                                    label: 'global.literals.addEvidences',
                                    required: false
                                },
                                data: {
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong',
                                    accept: '',
                                    button: true
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        notificationsByAddresses: {
            title: 'global.literals.createNewNotifications',
            size: '',
            icon: 'fa fa-check-square-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'row1',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'addresses',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-notification-addresses'
                                },
                                data: {
                                    addresses: [],
                                    notificationTypes: []
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        notificationNew: {
            title: 'global.literals.new_notification_title',
            size: '',
            icon: 'fa fa-check-square-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'row2',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'thirds',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-select-thirds'
                                },
                                data: {
                                    title: 'global.literals.receivers',
                                    thirds: [],
                                    dossier: undefined
                                }
                            }
                        ]
                    },
                    {
                        key: 'row3',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'documents',
                                type: 'annexaComponent',
                                templateOptions: {
                                    type: 'annexa-select-documents'
                                },
                                data: {
                                    title: 'global.literals.documents',
                                    documents: []
                                }
                            }
                        ]
                    },
                    {
                        key: 'row4',
                        className: '',
                        templateOptions: {},
                        fieldGroup: [
                            {
                                key: 'extract',
                                type: 'annexaTextAreaRow',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.reason',
                                    required: true
                                },
                                data: {
                                    informed: true,
                                    row: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        notificationModify: {
            title: 'global.literals.edit_notification_title',
            size: 'modal-lg',
            icon: 'fa fa-check-square-o',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-4">',
                '       <annexa-label-value label="global.literals.type" value="modal.data.notificationType[modal.languageColumn]"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-8">',
                '       <annexa-label-value label="global.literals.reason" value="modal.data.extract"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12">',
                '       <annexa-label-value label="global.literals.documents" type="documentList" value="modal.data.documents" origin="NOTIFICATION"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12" ng-if="!modal.data.id">',
                '       <annexa-label-value label="global.literals.receivers" type="thirdList" value="modal.receivers"></annexa-label-value>',
                '   </div>',
                '   <div class="col-lg-12" ng-if="modal.data.id">',
                '       <annexa-notification-statuses-evidences notification="modal.data"></annexa-notification-statuses-evidences>',
                '   </div>',
                '</div>'
            ],
            alerts: []
        },
        publishDocument: {
            title: 'global.literals.publish',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <annexa-document-publish document="modal.data.document" submit-function="modal.submitFunction" dossier-id="{{modal.originId}}"></annexa-document-publish>',
                '</div>'
            ],
            alerts: []
        },
        publishDocuments: {
            title: 'global.literals.publish',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <annexa-documents-publish documents="modal.data.documents" submit-function="modal.submitFunction" dossier-id="{{modal.originId}}"></annexa-documents-publish>',
                '</div>'
            ],
            alerts: []
        },
        notificationModify1: {
            title: 'global.literals.edit_notification_title',
            size: '',
            icon: 'fa fa-check-square-o',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field is-read-only="{{modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\'}}" type="select" label="global.literals.type" model-value="modal.data.notificationType" model-property="notificationType" save="modal.updateNotification(val, prop)" required="true" blur="ignore" lang-col="modal.language" select-options="modal.notificationTypes"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field is-read-only="{{modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\' || modal.data.notificationStatus.id == \'SEND\' || modal.data.notificationStatus == \'SEND\'}}" type="select" label="global.literals.state" model-value="modal.data.notificationStatus" model-property="notificationStatus" save="modal.updateNotification(val, prop)" required="true" blur="ignore" lang-col="modal.language" select-options="modal.notificationStatuses"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field is-read-only="{{modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\'}}" type="annexa-checkbox-list" label="global.literals.receivers" model-value="modal.receivers" model-property="receivers" save="modal.updateNotification(val, prop)" required="false" blur="ignore" label-col="modal.languageRec" print-function="modal.printReceivers"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row m-b-sm">',
                '   <div class="col-sm-12">       ',
                '       <label class=" small m-b-0">',
                '           <span class="ng-scope ng-binding" translate="global.literals.documents">Documents</span>',
                '       </label>',
                '   </div>',
                '</div>',
                '<div class="row  m-b-sm">',
                '   <div class="col-lg-12" data-ng-repeat="document in modal.documents" ng-if="document.checked || modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\'">',
                '       <input type="checkbox" ng-if="modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\'" class="m-r-0" ng-model="document.checked"  ng-change="modal.updateNotification(document, \'documents\')" ng-checked="document.checked" id="{{document + \'_\' + $index}}">',
                '       <a href ng-click="modal.viewOrDownloadDocument(document.currentDocument, document.fileName, document.currentFileType);" class="text-primary _400 small-lg "><span class="_600">{{document.name}}</span></a> ',
                '   </div>',
                '</div>',
                '<div class="row p-t-xs">',
                '   <div class="col-lg-12">',
                '       <annexa-editable-field is-read-only="{{modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\'}}" type="textarea" label="global.literals.reason" model-value="modal.data.extract" model-property="extract" save="modal.updateNotification(val, prop)" blur="ignore" maxlength="500" rows="3"></annexa-editable-field>',
                '   </div>',
                '</div>',
                '<div class="row m-b-sm" ng-hide="modal.data.notificationStatus.id == \'PENDING_SEND\' || modal.data.notificationStatus == \'PENDING_SEND\'">',
                '   <div class="col-sm-9">       ',
                '       <label class=" small m-b-0">',
                '           <span class="ng-scope ng-binding" translate="global.literals.evidences">Evidencies</span>',
                '       </label>',
                '   </div>',
                '   <div class="col-sm-3">       ',
                '       <button type="button" ng-click="modal.addEvidenceModal(modal.data, modal)" class="btn btn-sm grey inline">',
                '           <span translate="global.literals.newEvidence" class="ng-scope">New Evidence</span>',
                '       </button>',
                '   </div>',
                '</div>',
                '<div class="row  m-b-sm" data-ng-repeat="evidence in modal.data.evidences">',
                '   <div class="col-lg-12">',
                '       <a href ng-click="modal.viewOrDownloadDocument(evidence.evidence, evidence.name);" class="text-primary _400 small-lg "><span class="_600">{{\'global.literals.evidence\' | translate}} {{evidence.name}}</span></a> ',
                '   </div>',
                '</div>',
            ],
            submitModal: function () {
            }
        },
        editReceiverAndDocuments: {
            title: 'global.literals.edit_receiver_and_documents_modal_title',
            size: '',
            icon: 'fa fa-check-square-o',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'thirds',
                                fieldGroup: [
                                    {
                                        key: 'thirdsName',
                                        type: 'annexaLabel',
                                        templateOptions: {
                                            label: '',
                                            value:''
                                        },
                                        data: {
                                            informed: true,
                                            row: true,
                                            colClass: ' col-sm-12',
                                            labelClass: 'label-strong'
                                        }
                                    }
                                ]
                            },
                            {
                                key: 'documents',
                                fieldGroup: [
                                    {
                                        key: 'documentsName',
                                        type: 'annexaLabel',
                                        templateOptions: {
                                            label: '',
                                            value:''
                                        },
                                        data: {
                                            informed: true,
                                            row: true,
                                            colClass: ' col-sm-12',
                                            labelClass: 'label-strong'
                                        }
                                    }
                                ]
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            }
        },
        createDossierTransactionPopup:{
            title: 'global.literals.createTram',
            size: 'modal-lg',
            icon: 'fa fa-check',
            submitModal: function () {
            },
            alerts: []
        },
        sendProposalDossierTransactionPopup:{
            title: 'global.tram.literals.sendProposal',
            size: 'modal-lg',
            icon: 'fa fa-send',
            submitModal: function () {
            },
            alerts: []
        },
        sendGuidedProposalDossierTransactionPopup:{
            title: 'global.tram.literals.sendProposal',
            size: 'modal-lg',
            icon: 'fa fa-send',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'nextDossierTransactionInfo',
                                type: 'annexaNextDossierTransactionInfoRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    options:{},
                                    transaction:{}
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        addFooterClaimPopup:{
            title: 'global.sec.literals.addFooterClaimPopup',
            size: '',
            icon: '&#xe02e',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'footerClaim',
                                type: 'annexaFilterInputRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    required: true,
                                    label: 'global.literals.DOSSIER',
                                    placeholder: 'global.literals.placeholder_search_dossiers',
                                    search: function() {},
                                    advancedSearch: function() {},
                                    advancedSearchAdd: function(object, modal) {
                                        var proccesResult = function (object) {
                                            return object.language1;
                                        };
                                        object.value = proccesResult(object.object);
                                        //modal.annexaFormly.model.modal_body.footerClaim = object;
                                        this.close();
                                    }
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        changePassword: {
            title: 'global.literals.changePassword',
            size: '',
            icon: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'oldPassword',
                                type: 'annexaInputRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    type: 'password',
                                    label: 'global.literals.oldPassword',
                                    required: true,
                                    focus: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'newPassword',
                                type: 'annexaInputRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    type: 'password',
                                    label: 'global.literals.newPassword',
                                    required: true,
                                    focus: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            },
                            {
                                key: 'rePassword',
                                type: 'annexaInputRow',
                                className: 'col-sm-4',
                                templateOptions: {
                                    type: 'password',
                                    label: 'global.literals.repassword',
                                    required: true,
                                    focus: false
                                },
                                data: {
                                    row: true,
                                    informed: true,
                                    colClass: ' col-sm-12',
                                    labelClass: 'label-strong'
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        secDocFromTemplate: {
            title: 'global.literals.dossier_doc_from_template_title',
            size: 'modal-md',
            icon: 'fa fa-user',
            annexaFormly: {
                fields: [
                {
                    key: 'modal_body',
                    className: 'modal-body p-lg',
                    fieldGroup: [
                        {
                            key: 'docType',
                            type: 'annexaSelectRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                required: true,
                                focus: true,
                                label: 'global.literals.type',
                                optionsAttr: 'bs-options',
                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                valueProp: 'id',
                                labelProp: 'language1',
                                placeholder: '',
                                options: [],
                                type: undefined,
                                onSelected: function($item){}
                            },
                            controller: ['$scope', '$rootScope', 'Language', 'GlobalDataFactory', 'CustomFieldFactory', function($scope, $rootScope, Language, GlobalDataFactory, CustomFieldFactory) {
                            	$scope.to.onSelected = function($item){
                            		$rootScope.$broadcast('AnnexaSECDocChangeType');  
                                };
                                $scope.to.labelProp = Language.getActiveColumn();
                                var documentType = $rootScope.app.configuration.sec_document_types[$scope.to.type];
                                if(documentType && documentType.id) {
                                	var options = [];
                                	_.forEach(documentType.id, function(id){
                                		if(GlobalDataFactory.allDocumentTypes){
                                			var docType = $linq(GlobalDataFactory.allDocumentTypes).where("x => x.id == "+id).toArray();
                                			if(docType && docType.length > 0){
                                				_.forEach(docType, function(dt){
                                					if(!_.contains(options, dt)){
                                						options.push(dt);
                                					}
                                				});
                                			}
                                		}
                                	});
                                	$scope.to.options = options;
                                	if($scope.to.options && $scope.to.options.length == 1){
                                		$scope.model.docType = $scope.to.options[0].id;
                                	}
                                	if($scope.to.options && $scope.to.options.length > 0){
                                		_.forEach($scope.to.options, function (type) {
                                            var custom_fields = $linq(type.customFields).orderBy("x => x.viewOrder").toArray();
                                            var fieldsCF = [];
                                            _.forEach(custom_fields, function (field) {
                                                var formCF = new AnnexaFormly();
                                                formCF = CustomFieldFactory.addFormlyFieldRow(formCF, field.customField, field.required, type.id + '_' + field.customField.id);
                                                formCF.fields[0].className = 'col-sm-12';
                                                formCF.fields[0].hideExpression = function ($viewValue, $modelValue, scope) {
                                                    if (!field.noEditable) {
                                                        if (!scope.model  || !scope.model.docType || !scope.model.docType || type.id != scope.model.docType) {
                                                            return true;
                                                        }
                                                    } else {
                                                        return true;
                                                    }
                                                }


                                                fieldsCF.push(formCF.fields[0]);
                                                $scope.model['cf_' + type.id + '_' + field.customField.id] = CustomFieldFactory.calculateValueCustomField(field, $scope.model, ('cf_' + type.id + '_' + field.customField.id));
                                            });
                                            $scope.fields.push.apply($scope.fields, fieldsCF);
                                        });
                                	}
                                	
                                }
                            }],
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            }
                        },
                        {
                            key: 'secTemplate',
                            type: 'annexaSelectRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                label: 'global.literals.template',
                                optionsAttr: 'bs-options',
                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                options: [],
                                valueProp: 'id',
                                labelProp: 'language1',
                                placeholder: '',
                                required: true,
                                type: undefined,
                                organId: undefined
                            },
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            },
                            controller:['$scope', 'SecFactory', '$filter', 'CommonService', 'Language', '$rootScope',  function($scope, SecFactory, $filter, CommonService, Language, $rootScope) {
                            	$scope.to.labelProp = Language.getActiveColumn();
                            	var documentType = $rootScope.app.configuration.sec_document_types[$scope.to.type];
                                var templates = $linq(SecFactory.secTemplates).where(function(x){
                                	if(documentType && documentType.id && documentType.id.length > 0 && x.documentType && x.documentType.id){
                                		if(_.contains(documentType.id, x.documentType.id+"") && $scope.to.type == x.type){
                                			if(!$scope.to.organId || !x.organs || x.organs.length == 0 || ($scope.to.organId && x.organs.length > 0 && $linq(x.organs).contains($scope.to.organId, "(x, y) => x.id == y"))){
                                				if(x.profiles && x.profiles.length > 0){
                                                    var profiles = $linq(x.profiles).intersect($rootScope.LoggedUser.userProfiles, function(x,y){
                                                        if(y.profile){
                                                            return (x.profile.id == y.profile.id);
                                                        }
                                                        else{
                                                            return true;
                                                        }
                                                    }).toArray();
                                                    if(profiles && profiles.length > 0){
                                                       return true;
                                                    } else {
                                                    	return false;
                                                    }
                                                }
                                				return true;
                                			}
                                		}
                                	}
                                	return false;
                                }).toArray();

                                $scope.to.options = ((templates && templates.length > 0)?templates:[]);
                                if($scope.to.options && $scope.to.options.length == 1){
                            		$scope.model.secTemplate = $scope.to.options[0].id;
                            	}
                            }]
                        },
                        {
                            key: 'documentTitle',
                            type: 'annexaInputRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                type: 'text',
                                label: 'global.literals.title',
                                required: true,
                                focus: false,
                                sessionDate:undefined,
                                acronymOrgan: undefined
                            },
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            },
                            controller: ['$scope', 'SecFactory', '$filter', 'CommonService', 'Language', '$rootScope', 'GlobalDataFactory',  function($scope, SecFactory, $filter, CommonService, Language, $rootScope, GlobalDataFactory) {
                            	var labelProp = Language.getActiveColumn();
                            	$scope.changeFunctionTitle = function(viewValue){
                            		if($rootScope && ($rootScope.esetMode || ($rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.validate_title_document && $rootScope.app.configuration.validate_title_document.value))){
                                        if($scope.model.docType) {
                                            var selectedType = $linq(GlobalDataFactory.allDocumentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.docType);
                                            if(selectedType) {
                                            	if ($scope.lastSelectedType) {
                                            		//Ya hay un tipo de documento seleccionado
                                            		if(!$scope.model.documentTitle){
                                            			$scope.model.documentTitle = "";
                                            		}
                                            		var pos = $scope.model.documentTitle.indexOf($scope.lastSelectedType);
                                            		if (pos == 0) {
                                            			//Encuentra el acronimo del tipo de documento anterior, lo reemplaza por el nuevo acronimo
                                            			$scope.model.documentTitle = $scope.model.documentTitle.replace($scope.lastSelectedType, (selectedType.acronym + ' '+selectedType[labelProp]));
                                            		} else {
                                            			//No encuentra el acronimo del tipo de documento anterior, pone el nuevo acronimo al principio
                                            			$scope.model.documentTitle = selectedType.acronym + ' '+selectedType[labelProp] + ' ' + $scope.model.documentTitle;
                                            		}                                                		                                                		
                                            	} else {
                                            		//No hay un tipo de documento seleccionado
                                            		if (viewValue) {
                                            			if (viewValue.startsWith(selectedType.acronym + ' '+selectedType[labelProp])) {
                                            				$scope.model.documentTitle = viewValue;
                                            			} else {
                                            				$scope.model.documentTitle = selectedType.acronym + ' '+selectedType[labelProp] + ' ' + viewValue;
                                            			}
                                            		} else {
                                            			$scope.model.documentTitle = selectedType.acronym + ' '+selectedType[labelProp]+(($scope.to.acronymOrgan)?' '+$scope.to.acronymOrgan:'')+(($scope.to.sessionDate)?' '+$filter('date')(new Date($scope.to.sessionDate), 'ddMMyyyy'):'');
                                                    }
                                            	}  
                                            	$scope.lastSelectedType = selectedType.acronym + ' '+selectedType[labelProp];
                                            }                                                
                                        }
                                    }else{
                                    	if($scope.model.docType) {
	                                    	var selectedType = $linq(GlobalDataFactory.allDocumentTypes).singleOrDefault(undefined, "x => x.id == " + $scope.model.docType);
	                                        if(selectedType) {   
	                                        	if ($scope.lastSelectedType) {
                                            		//Ya hay un tipo de documento seleccionado
                                            		if(!$scope.model.documentTitle){
                                            			$scope.model.documentTitle = "";
                                            		}
                                            		var pos = $scope.model.documentTitle.indexOf($scope.lastSelectedType);
                                            		if (pos == 0) {
                                            			//Encuentra el acronimo del tipo de documento anterior, lo reemplaza por el nuevo acronimo
                                            			$scope.model.documentTitle = $scope.model.documentTitle.replace($scope.lastSelectedType, selectedType[labelProp]);
                                            		} else {
                                            			//No encuentra el acronimo del tipo de documento anterior, pone el nuevo acronimo al principio
                                            			$scope.model.documentTitle =  selectedType[labelProp] + ' ' + $scope.model.documentTitle;
                                            		}                                                		                                                		
                                            	} else {
                                            		//No hay un tipo de documento seleccionado
                                            		if (viewValue) {
                                            			if (viewValue.startsWith(selectedType[labelProp] + ' ')) {
                                            				$scope.model.documentTitle = viewValue;
                                            			} else {
                                            				$scope.model.documentTitle = selectedType[labelProp] + ' ' + viewValue;
                                            			}
                                            		} else {
                                            			$scope.model.documentTitle = selectedType[labelProp]+(($scope.to.acronymOrgan)?' '+$scope.to.acronymOrgan:'')+(($scope.to.sessionDate)?' '+$filter('date')(new Date($scope.to.sessionDate), 'dd-MM-yyyy'):'');
                                                    }
                                            	}  
                                            	$scope.lastSelectedType = selectedType[labelProp];
	                                        }
                                    	}
                                    }
                                };
                                $scope.changeFunctionTitle();
                                $scope.to.onFocus = function(viewValue){
                                	$scope.changeFunctionTitle(viewValue);
                                }
                                $scope.$on('AnnexaSECDocChangeType', function(event, args){
                                	$scope.changeFunctionTitle();
                                });
                            }]
                        },
                        {
                            key: 'section',
                            type: 'annexaSelectRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                required: true,
                                focus: false,
                                label: 'global.literals.section',
                                optionsAttr: 'bs-options',
                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                valueProp: 'id',
                                labelProp: 'language1',
                                placeholder: '',
                                options: []
                            },
                            controller: ['$scope', '$rootScope', 'Language', function($scope, $rootScope, Language) {
                                $scope.to.labelProp = Language.getActiveColumn();
                                $scope.to.options = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();
                                if($scope.to.options && $scope.to.options.length == 1){
                            		$scope.model.section = $scope.to.options[0].id;
                            	}
                            }],
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            },
                            hideExpression: function ($viewValue, $modelValue, scope) {
                                var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                var sections = $linq($rootScope.LoggedUser.userSections).distinctBy("x => x.section.id").select("x => x.section").toArray();

                                if(sections.length == 1){
                                	scope.model.section = sections[0].id;
                                    return true;
                                }

                                return false;
                            }
                        },
                        {
                            key: 'profile',
                            type: 'annexaSelectRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                required: true,
                                focus: true,
                                label: 'global.literals.profile',
                                optionsAttr: 'bs-options',
                                ngOptions: 'option[to.valueProp] as option in to.options | filter: $select.search',
                                valueProp: 'id',
                                labelProp: 'language1',
                                placeholder: '',
                                options: []
                            },
                            controller: ['$scope', '$rootScope', 'Language', 'GlobalDataFactory', function($scope, $rootScope, Language, GlobalDataFactory) {
                                $scope.to.labelProp = Language.getActiveColumn();
                                $scope.to.options = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
                                if($scope.to.options && $scope.to.options.length == 1){
                            		$scope.model.profile = $scope.to.options[0].id;
                            	}else if($scope.to.options && $scope.to.options.length > 1){
                            		var profiles = $linq($rootScope.LoggedUser.userDefaultEntityProfiles).where("x => x.entityType == 'SEC'").select("x => x.profile").toArray();
                                    if (profiles && profiles.length > 0) {
                                    	$scope.model.profile = profiles[0].id;
                                    }
                            	}
                            }],
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            },
                            hideExpression: function ($viewValue, $modelValue, scope) {
                                var $rootScope = angular.element(document.body).injector().get('$rootScope');
                                var profiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();

                                if(profiles.length == 1){
                                	scope.model.profile = profiles[0].id;
                                    return true;
                                }

                                return false;
                            }
                        },
                        {
                            key: 'archiveClassification',
                            type: 'annexaSelectTreeRow',
                            className: 'col-sm-12',
                            templateOptions: {
                                label: 'global.literals.classificationBox',
                                options: [],
                                required: false,
                                focus: false
                            },
                            data: {
                                row: true,
                                informed: true,
                                colClass: ' col-sm-12',
                                labelClass: 'label-strong'
                            }
                        }
                    ]
                }]
            },
            alerts: [],
            submitModal: function () {}
        },
        fromScannerWizard: {
            title: 'global.literals.fromScannerWizard',
            size: '',
            icon: '&#xe329',
            wizard: {
                modal: undefined,
                finishWizard: function () {
                    if(this.modal) {
                        this.modal.submitModal();
                    }
                },
                exitValidation: function(form) {
                    return form && !form.$invalid;
                },
                steps: [
                    {
                        title: 'global.literals.fromScannerWizardStep1',
                        key: 'selectDocuments',
                        disabled: false,
                        disableNext: function(model, wizard) {
                            var selected = false;
                            var $rootScope = angular.element(document.body).injector().get('$rootScope');
                            if($rootScope && ($rootScope.esetMode  || ($rootScope.app &&  $rootScope.app.configuration &&  $rootScope.app.configuration.validate_title_document && $rootScope.app.configuration.validate_title_document.value))){
                                wizard.steps[1].disabled = true;
                                wizard.steps[2].disabled = true;
                                _.forEach(Object.keys(model.documents), function (value) {
                                    selected = selected || model.documents[value];
                                    var indexStep = $linq(wizard.steps).indexOf(function (x) { return (x.key == value); });

                                    if (indexStep != -1) {
                                        wizard.steps[indexStep].disabled = !model.documents[value];
                                    }
                                });
                            }else {
                                wizard.steps[1].disabled = false;
                                wizard.steps[2].disabled = false;
                                _.forEach(Object.keys(model.documents), function (value) {
                                    selected = selected || model.documents[value];
                                    var indexStep = $linq(wizard.steps).indexOf(function (x) { return (x.key == value); });
                                    if (indexStep != -1) {
                                        wizard.steps[indexStep].disabled = true;
                                    }
                                });
                            }
                            return !selected;
                        },
                        exitValidation: function(model) {
                            var selected = false;

                            _.forEach(Object.keys(model.documents),function(value) {
                                selected = selected || model.documents[value];
                            });

                            return selected;
                        },
                        annexaFormly: {
                            fields: [
                                {
                                    key: 'selectAll',
                                    type: 'annexaSelectAllRow',
                                    className: '',
                                    templateOptions: {
                                        type: 'checkbox',
                                        label: 'global.bpm.selectAll',
                                        validate: false,
                                        required: false,
                                        changeValue: function(){}
                                    },
                                    controller:['$scope', function($scope) {
                                        $scope.to.changeValue = function(){
                                            if($scope.model.documents){
                                                var keys = Object.keys($scope.model.documents);
                                                _.forEach(keys, function(key){
                                                    if($scope.model.selectAll){
                                                        $scope.model.documents[key] = true;
                                                    }else{
                                                        $scope.model.documents[key] = false;
                                                    }
                                                });
                                            }
                                        };
                                    }],
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                        if(scope.model.isUploadDocuments){
                                            return true;
                                        }
                                        var keys = Object.keys(scope.model.documents);

                                        if(keys && keys.length < 2){
                                            return true;
                                        }
                                        return false;
                                    }
                                },
                                {
                                    key: 'documents',
                                    type: 'annexaRadioCheckboxRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    templateOptions: {
                                        type: 'checkHTMLOneModel',
                                        optionsAttr: 'bs-options',
                                        required: true,
                                        validate: true,
                                        options:[],
                                        ngOptions:'option[to.valueProp] as option in to.options',
                                        valueProp: 'id',
                                        labelProp: 'value'
                                    },
                                    watcher:{
                                        type: '$watchCollection',
                                        expression: 'model.documents',
                                        listener: function(field, _new, _old, scope) {
                                            var keys = Object.keys(_new);
                                            var isAllTrue = true;
                                            if(keys) {
                                                _.forEach(keys, function(key){
                                                    isAllTrue = isAllTrue && (_new[key])?true : false;
                                                });
                                            }else{
                                                isAllTrue = false;
                                            }
                                            scope.model.selectAll = isAllTrue;
                                        }
                                    },
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                        if(scope.model.isUploadDocuments){
                                            return true;
                                        }
                                        return false;
                                    },
                                    controller: ['$scope', '$rootScope', 'DccumentsFactory', function($scope, $rootScope, DccumentsFactory){
                                        $scope.to.showObject = function(option){
                                            if(option && option.id){
                                                $rootScope.loading(true);
                                                DccumentsFactory.getScanDocumentContent(option.id).then(function(data) {
                                                    window.open(data, '_blank');
                                                    $rootScope.loading(false);
                                                }).catch(function (error) {
                                                    $rootScope.loading(false);
                                                });
                                            }
                                        }
                                    }]
                                },
                                {
                                    key: 'documentsLabels',
                                    type: 'annexaLabelsRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    templateOptions: {
                                        labels: []
                                    },
                                    controller: ['$scope', function($scope) {
                                        if($scope.model.documents){
                                            $scope.to.labels = Object.keys($scope.model.documents);
                                        }else{
                                            $scope.to.labels = [];
                                        }
                                    }],
                                    data: {
                                        informed:true,
                                        row:true,
                                        colClass: ' col-sm-12',
                                        labelClass: 'label-strong'
                                    },
                                    hideExpression: function($viewValue, $modelValue, scope) {
                                        if(scope.model.isUploadDocuments){
                                            return false;
                                        }
                                        return true;
                                    }
                                }
                            ]
                        }
                    },
                    {
                        title: 'global.literals.fromScannerWizardStep2',
                        key: 'selectDocumentsUpload',
                        disabled: true,
                        disableNext: function(model, wizard) {
                            var selected = false;
                            if(model.uploadType){
                                _.forEach(Object.keys(wizard.steps[0].annexaFormly.model.documents),function(value) {
                                    var indexStep = $linq(wizard.steps).indexOf(function (x) { return (x.key == value); });

                                    if (indexStep != -1) {
                                        if(model.uploadType == 1){
                                            wizard.steps[indexStep].disabled = !wizard.steps[0].annexaFormly.model.documents[value];
                                        }else{
                                            wizard.steps[indexStep].disabled = true;
                                        }
                                    }

                                });
                                if(wizard.steps.length > 3 && wizard.steps[2].key == "documentAllInOne") {
                                    if(model.uploadType == 1){
                                        wizard.steps[2].disabled = true;
                                    }else{
                                        wizard.steps[2].disabled = false;
                                    }
                                }

                                selected = true;
                            }
                            return !selected;
                        },
                        exitValidation: function(model) {
                            var selected = false;
                            if(model.uploadType){
                                selected = true;
                            }
                            return selected;
                        },
                        annexaFormly: {
                            fields: [
                                {
                                    key: 'uploadType',
                                    type: 'annexaRadioCheckboxRow',
                                    className: 'col-sm-12 daughter-label-strong',
                                    data: {
                                        informed: true,
                                        row: true
                                    },
                                    templateOptions: {
                                        type: 'radio',
                                        optionsAttr: 'bs-options',
                                        required: true,
                                        validate: true,
                                        options:[],
                                        ngOptions:'option[to.valueProp] as option in to.options',
                                        valueProp: 'id',
                                        labelProp: 'value'
                                    },
                                    controller: function ($scope, $filter) {
                                        $scope.to.options = [
                                            { id:1, value: $filter("translate")("global.documents.uploadSimpleDocuments") },
                                            { id:2, value: $filter("translate")("global.documents.uploadDocuments") }
                                        ];
                                    }
                                }
                            ]
                        }
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        },
        reopenTask: {
            title: 'global.task.reopen',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                            {
                                key: 'comments',
                                type: 'annexaTextAreaRow',
                                className: 'col-sm-12',
                                templateOptions: {
                                    type: 'text',
                                    label: 'global.literals.reason',
                                    required: true,
                                    focus: false
                                }
                            }
                        ]
                    }
                ]
            },
            alerts: [],
            submitModal: function () {}
        },
        pluginCreate: {
            title: 'global.literals.pluginCreate',
            size: '',
            data: undefined,
            content: [
                '<div class="row p-t-xs">',
                '   <annexa-plugins-create data="modal.data" submit-function="modal.submitFunction"></annexa-plugins-create>',
                '</div>'
            ],
            alerts: []
        },
        newTaskAddProfileToDocument: {
            title: 'global.tram.literals.add_user_profiles',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'userModifyDocument',
                        type: 'annexaRadioCheckboxRow',
                        className: 'col-sm-12 daughter-label-strong',
                        data: {
                            informed: true,
                            row: true
                        },
                        templateOptions: {
                            type: 'radio',
                            optionsAttr: 'bs-options',
                            required: true,
                            validate: true,
                            options:[],
                            ngOptions:'option[to.valueProp] as option in to.options',
                            valueProp: 'id',
                            labelProp: 'value',
                            label: 'global.tram.literals.add_user_profiles_literal'
                        },
                        controller: function ($scope, $filter) {
                            $scope.to.options = [
                                { id:1, value: $filter("translate")("global.literals.yes") },
                                { id:2, value: $filter("translate")("global.literals.no") }
                            ];
                        }
                    },
                    {
                        key: 'profile',
                        type: 'annexaSelectRow',
                        className: 'col-sm-12',
                        templateOptions: {
                            optionsAttr: 'bs-options',
                            ngOptions: 'option in to.options | filter: $select.search',
                            label: 'global.tram.literals.profile_to_add',
                            valueProp: 'id',
                            labelProp: 'language1',
                            placeholder: '',
                            options: [],
                            required: true,
                            focus: false
                        },
                        controller: ['$scope', 'Language', 'SecFactory', function ($scope, Language, SecFactory) {
                            $scope.to.labelProp = Language.getActiveColumn();
                            $scope.to.options = (($scope.model && $scope.model.modal_body && $scope.model.modal_body.userProfiles)? $scope.model.modal_body.userProfiles : []);
                        }],
                        data: {
                            informed:true,
                            row:true,
                            clear: function($event,model,key, $select) {
                                $event.stopPropagation();
                                model[key] = undefined;
                                if($select) {
                                    $select.selected = undefined;
                                    $select.search = undefined;
                                }
                            }
                        },
                        hideExpression: function($viewValue, $modelValue, scope) {
                            return scope.model.userModifyDocument != 1;
                        }
                    }
                ]
            },
            submitModal: function () {
            }
        },
        selectDossierTransactionToAddDocument: {
            title: 'global.literals.modalNewDocumentTitle',
            size: '',
            annexaFormly: {
                fields: [
                    {
                        key: 'modal_body',
                        className: 'modal-body p-lg',
                        fieldGroup: [
                        	{
								 key: 'dossierTransactionAdded',
								 type: 'annexaRadioCheckboxRow',
								 className: 'col-sm-12 daughter-label-strong',
								 data: {
								     informed: true,
								     row: true
								 },
								 templateOptions: {
								     type: 'radio',
								     optionsAttr: 'bs-options',
								     required: true,
								     validate: true,
								     options:[],
								     ngOptions:'option[to.valueProp] as option in to.options',
								     valueProp: 'id',
								     labelProp: 'value',
								     label: 'global.tram.literals.select_open_dossier_transaction'
								 },
								 controller: ['$scope', '$filter', 'Language', function ($scope, $filter, Language) {
									 $scope.to.options = [];
							         if($scope.model.dossierTransactions && $scope.model.dossierTransactions.length > 0){
							        	 _.forEach($scope.model.dossierTransactions, function(dossierTransaction){
							        		 $scope.to.options.push({ id:dossierTransaction.id, value: dossierTransaction[Language.getActiveColumn()] });
							        	 });
							         }
								 }]
                            },
                            {
 								 key: 'createdDocumentType',
 								 type: 'annexaRadioCheckboxRow',
 								 className: 'col-sm-12 daughter-label-strong',
 								 data: {
 								     informed: true,
 								     row: true
 								 },
 								 templateOptions: {
 								     type: 'radio',
 								     optionsAttr: 'bs-options',
 								     required: true,
 								     validate: true,
 								     options:[],
 								     ngOptions:'option[to.valueProp] as option in to.options',
 								     valueProp: 'id',
 								     labelProp: 'value',
 								     label: 'global.tram.literals.created_document_type'
 								 },
 								 controller: ['$scope', '$filter', 'Language', function ($scope, $filter, Language) {
 									 $scope.to.options = [];
 							         $scope.to.options.push({ id:'tram', value: $filter('translate')('global.tram.literals.from_tram_template') });
 							         $scope.to.options.push({ id:'doc', value: $filter('translate')('global.tram.literals.from_document') });
 								 }]
                           }
                        ]
                    }
                ]
            },
            submitModal: function () {
            },
            alerts: []
        }
        
    });
