
/**
 * Created by osirvent on 20/01/2016.
 */
angular
    .module('annexaApp')
    .controller('TasksController',['$state','$filter','$scope', 'TaskFactory', 'TableFilter', 'Language','api', '$stateParams', '$rootScope', 'globalModals', 'RestService','AnnexaFormlyFactory', 'HelperService', 'AnnexaModalFactory', 'TableFilterFactory', 'RouteStateFactory',
        function($state, $filter,$scope, TaskFactory, TableFilter, Language, api, $stateParams, $rootScope, globalModals, RestService, AnnexaFormlyFactory, HelperService, AnnexaModalFactory, TableFilterFactory, RouteStateFactory) {
            $scope.languageColumn = Language.getActiveColumn();
            var paramsToFilter = [];

            $scope.expiredStates = [
                { id: 'expired', name: $filter('translate')('global.literals.outOfTime') },
                { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') }
            ];

            $scope.taskStates = [
                { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') },
                { id: 'PENDING', name: $filter('translate')('PENDING') },
                { id: 'FINISHED', name: $filter('translate')('FINISHED') },
                { id: 'CANCELLED', name: $filter('translate')('CANCELLED') },
                { id: 'IN_PROGRESS_AND_PENDING', name: $filter('translate')('IN_PROGRESS_AND_PENDING') }
            ];

            $scope.filterData = { loggedUser: $rootScope.LoggedUser.id};

            $scope.searchTasks = function (value) {
                var valueOk = value;
                if(valueOk && valueOk.val){
                    valueOk = valueOk.val;
                }
                if(!valueOk) {
                    return [];
                }else if(valueOk != '*' && valueOk.length < 3){
                    return [];
                }else{
                    if(valueOk == '*'){
                        valueOk = '';
                    }
                    return RestService.loadData('common','User',valueOk).then(function(dataSearch) {
                        var usersLoad = [];
                        if(dataSearch.data &&  dataSearch.data.content && dataSearch.data.content.length > 0){
                            angular.forEach(JSOG.decode(dataSearch.data.content), function(val, key) {
                                var name = val.name+" "+val.surename1;
                                if(val.surename2){
                                    name = name+" "+val.surename2;
                                }
                                usersLoad.push({ 'id': val.id, 'user': val, 'value': name});
                            });
                        }
                        return usersLoad;
                    }).catch(function(){
                        return [];
                    })
                }
            };

            $scope.submit = function (val) {
                 $scope.$broadcast('filterData', {});
            };

            $scope.newTask = function(){
                $state.transitionTo('annexa.tasks.createdTasks.new');
            };

    }])
    .controller('ManageTasksController',['$filter','$state','$scope', 'TaskFactory','api', '$stateParams', '$rootScope', 'HeaderService', 'DTColumnBuilder', 'HelperService', 'globalModals', 'AnnexaFormlyFactory', 'TableFilterFactory', 'RouteStateFactory', 'RestService',
        function($filter, $state, $scope, TaskFactory, api, $stateParams, $rootScope, HeaderService, DTColumnBuilder, HelperService, globalModals, AnnexaFormlyFactory, TableFilterFactory, RouteStateFactory, RestService) {
            $scope.tfilter = [
                { id: 'description', type: 'text', order: 0, label: 'global.literals.task_name' },
                { id: 'createdUser', type: 'text', order: 1, label: 'global.literals.started_for', callAux: true},
                { id: 'user', type: 'text', order: 2, label: 'global.literals.assign_to', callAux: true},
                { id: 'startDates', type: 'dateRange', order: 3, label: 'global.literals.startDate', callAux: true},
                { id: 'endDates', type: 'dateRange', order: 4, label: 'global.literals.endDate', callAux: true},
                { id: 'expired', type: 'select', order: 5, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy($scope.expiredStates), addAll: true, nameProperty: 'name', callAux: true},
                { id: 'state', type: 'select', order: 6, label: 'global.literals.state', dataType: 'LOCAL', data: angular.copy($scope.taskStates), addAll: true, nameProperty: 'name', callAux: true}
            ];
            var routeState = RouteStateFactory.getRouteState($state.current);
            if(routeState) {
                $scope.tfilter = routeState.state;
            }
            $scope.filterData.assigned = false;
            $scope.filterData.manage = true;
            $scope.numCanceledTask = 0;

            if($stateParams.type){
                var filterState = $linq($scope.tfilter).where("x => x.id == 'state'").toArray();
                var filterExpired= $linq($scope.tfilter).where("x => x.id == 'expired'").toArray();
                if($stateParams.type == 'C' && filterState) {
                    filterState[0].model = { id: 'IN_PROGRESS_AND_PENDING', name: $filter('translate')('IN_PROGRESS_AND_PENDING') };
                }else if($stateParams.type == 'CE' && filterState && filterExpired){
                    filterExpired[0].model = { id: 'expired', name: $filter('translate')('global.literals.outOfTime') };
                    filterState[0].model = { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') };
                }else if($stateParams.type == 'CNE' && filterState && filterExpired){
                    filterExpired[0].model = { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') };
                    filterState[0].model = { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') };
                }else if($stateParams.type == 'CP' && filterState){
                    filterState[0].model = { id: 'PENDING', name: $filter('translate')('PENDING') };
                }else if($stateParams.type == 'CF' && filterState){
                    filterState[0].model = { id: 'FINISHED', name: $filter('translate')('FINISHED') };
                }
            }
            $scope.getFilterCall = function() {
                var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);;
                return filterCall;
            };

            $scope.getFilterCallAux = function(){
                var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);

                filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                filterCallAux.assigned = $scope.filterData.assigned;
                filterCallAux.manage = $scope.filterData.manage;

                if(filterCallAux.expired){
                    if(filterCallAux.expired.id == 'expired'){
                        filterCallAux.expired = true;
                    }else if(filterCallAux.expired.id == 'noExpired'){
                        filterCallAux.expired = false;
                    }
                }

                if(filterCallAux.state){
                    if(filterCallAux.state.id){
                        filterCallAux.state = filterCallAux.state.id;
                    }else{
                        filterCallAux.state = '';
                    }
                }

                return filterCallAux;
            };

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.tasks.manageTasks') {
                    $rootScope.headButtons = [
                        new HeadButton('btn primary breadDivButton ', undefined, '#dataTableTask','fa-plus', 'global.literals.new_task_title', undefined, 'newTask').setPermissions('create_task')
                    ];
                    $rootScope.subHeadButtons = [new HeadButtonSearch('#tableFilter')];
                    
                    RestService.findByUrl("./api/tasks/countCanceledTask" + "?createdUser=" + $rootScope.LoggedUser.id + "&endedTask=true").then(function(data){
                    	$scope.numCanceledTask = 0;
                    	if (data && data.data > 0) {
                    		$scope.numCanceledTask = data.data;
                    	}
	                    $rootScope.subHeadTabs = [
	                        {state:'annexa.tasks.assignedTasks', type:'breadDivButton', name:'global.literals.tasksAssigned'},
	                        {state:'annexa.tasks.createdTasks', type:'breadDivButton', name:'global.literals.tasksCreated', permissions: ['create_task']},
	                        {state: 'annexa.tasks.manageTasks', type: 'breadDivButton active', name: 'global.literals.manage', permissions: ['manage_tasks']}
	                    ];
                    }).catch(function(){
                    });
                    
                }
            });

            HeaderService.changeState($state.current);

            var daysColumn = new RemaingDaysGlobalColumn($filter, 'global.literals.days', HelperService, $rootScope.LoggedUser.entity.holidays);
            var userNameColumn =  new StrippedColumn($filter, 'global.literals.task_name', 100);
            var userStartedColumn = new UserColumn($filter, 'global.literals.created_for');
            var userCreateDateColumn = new DateColumn($filter, 'global.literals.creation_date');
            var userAssignedColumn = new UserColumn($filter,'global.literals.assign_to');
            var userStartDateColumn = new DateColumn($filter,'global.literals.startDate');
            var userExpiryDateColumn = new DateColumn($filter,'global.literals.endDate');
            var userActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.see','annexa.tasks.manageTasks.edit({ task: \'[data]\'})','fa-eye', true), []);

            $scope.columnsAux = [
                {id: 'remainingDays', width: '40px', column:daysColumn },
                {id: 'description', column: userNameColumn, sortable: false },
                {id: 'createdUser', column: userStartedColumn},
                {id: 'createdDate', column: userCreateDateColumn},
                {id: 'user', column: userAssignedColumn},
                {id: 'taskComments', width: '100px', column: new CommentColumn($filter, 'global.literals.comments', 'fa-comment', 'comments', 'createdUser', true)},
                {id: 'startDate', column: userStartDateColumn},
                {id: 'endDate', column: userExpiryDateColumn}
            ];

            var datatableSettings = $rootScope.app.configuration.datatables_settings;
            $scope.columnsManagedTasks = angular.copy($scope.columnsAux);
            $scope.columnsManagedTasks.push({ id: 'actions', columnName: 'id', width: '170px', className: 'text-center', title: userActionsColumn.getTitle(), render: userActionsColumn.getRender, sortable: false});
            $scope.columnsManagedTasks = getDatatableColumnsSettings(datatableSettings, 'datatable_task_created', $scope.columnsManagedTasks);

            var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_task_created', $scope.columnsManagedTasks);
            $scope.tableOrderProperties = {sortName: 'remainingDays', sort: [[0,'desc']]};
            if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > 0){
                $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
            }

            $scope.tableDefinition = {
                id: 'tableManagedTasks',
                origin: 'common',
                objectType: 'TaskView',
                sortName: $scope.tableOrderProperties.sortName,
                sort: $scope.tableOrderProperties.sort,
                filterCall: $scope.getFilterCall(),
                filterCallAux: $scope.getFilterCallAux(),
                filterCallFunc: $scope.getFilterCall,
                filterCallAuxFunc: $scope.getFilterCallAux,
                columns: angular.copy($scope.columnsManagedTasks),
                containerScope: $scope
            }
        }])
    .controller('CreatedTasksController',['$filter','$state','$scope', 'TaskFactory','api', '$stateParams', '$rootScope', 'HeaderService', 'DTColumnBuilder', 'HelperService', 'globalModals', 'AnnexaFormlyFactory', 'RouteStateFactory', 'TableFilterFactory', 'RestService',
        function($filter, $state, $scope, TaskFactory, api, $stateParams, $rootScope, HeaderService, DTColumnBuilder, HelperService, globalModals, AnnexaFormlyFactory, RouteStateFactory, TableFilterFactory, RestService) {
            $scope.tfilter = [
                { id: 'description', type: 'text', order: 0, label: 'global.literals.task_name' },
                { id: 'createdUser', type: 'text', order: 1, label: 'global.literals.started_for', callAux: true},
                { id: 'user', type: 'text', order: 2, label: 'global.literals.assign_to', callAux: true},
                { id: 'startDates', type: 'dateRange', order: 3, label: 'global.literals.startDate', callAux: true},
                { id: 'endDates', type: 'dateRange', order: 4, label: 'global.literals.endDate', callAux: true},
                { id: 'expired', type: 'select', order: 5, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy($scope.expiredStates), addAll: true, nameProperty: 'name', callAux: true},
                { id: 'state', type: 'select', order: 6, label: 'global.literals.state', dataType: 'LOCAL', data: angular.copy($scope.taskStates), addAll: true, nameProperty: 'name', callAux: true}
            ];
            var routeState = RouteStateFactory.getRouteState($state.current);
            if(routeState) {
                $scope.tfilter = routeState.state;
            }
            $scope.filterData.assigned = false;
            $scope.filterData.manage = false;           
            $scope.numCanceledTask = 0;

            if($stateParams.type){
                var filterState = $linq($scope.tfilter).where("x => x.id == 'state'").toArray();
                var filterExpired= $linq($scope.tfilter).where("x => x.id == 'expired'").toArray();
                if($stateParams.type == 'C' && filterState) {
                    filterState[0].model = { id: 'IN_PROGRESS_AND_PENDING', name: $filter('translate')('IN_PROGRESS_AND_PENDING') };
                }else if($stateParams.type == 'CE' && filterState && filterExpired){
                    filterExpired[0].model = { id: 'expired', name: $filter('translate')('global.literals.outOfTime') };
                    filterState[0].model = { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') };
                }else if($stateParams.type == 'CNE' && filterState && filterExpired){
                    filterExpired[0].model = { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') };
                    filterState[0].model = { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') };
                }else if($stateParams.type == 'CP' && filterState){
                    filterState[0].model = { id: 'PENDING', name: $filter('translate')('PENDING') };
                }else if($stateParams.type == 'CF' && filterState){
                    filterState[0].model = { id: 'FINISHED', name: $filter('translate')('FINISHED') };
                }
            }
            $scope.getFilterCall = function() {
                var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
                return filterCall;
            };

            $scope.getFilterCallAux = function(){
                var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
                filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                filterCallAux.assigned = $scope.filterData.assigned;
                filterCallAux.manage = $scope.filterData.manage;

                if(filterCallAux.expired){
                    if(filterCallAux.expired.id == 'expired'){
                        filterCallAux.expired = true;
                    }else if(filterCallAux.expired.id == 'noExpired'){
                        filterCallAux.expired = false;
                    }
                }
                if(filterCallAux.state){
                    if(filterCallAux.state.id){
                        filterCallAux.state = filterCallAux.state.id;
                    }else{
                        filterCallAux.state = '';
                    }
                }
                return filterCallAux;
            };

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.tasks.createdTasks') {
                    $rootScope.headButtons = [
                        new HeadButton('btn primary breadDivButton ', undefined, '#dataTableTask','fa-plus', 'global.literals.new_task_title', undefined, 'newTask').setPermissions('create_task')
                    ];
                    $rootScope.subHeadButtons = [new HeadButtonSearch('#tableFilter')];
                    
                    RestService.findByUrl("./api/tasks/countCanceledTask" + "?createdUser=" + $rootScope.LoggedUser.id + "&endedTask=true").then(function(data){
                    	$scope.numCanceledTask = 0;
                    	if (data && data.data > 0) {
                    		$scope.numCanceledTask = data.data;
                    	}
	                    $rootScope.subHeadTabs = [
	                        {state:'annexa.tasks.assignedTasks', type:'breadDivButton', name:'global.literals.tasksAssigned'},
	                        {state:'annexa.tasks.createdTasks', type:'breadDivButton active', name:'global.literals.tasksCreated', permissions: ['create_task']},
	                        {state: 'annexa.tasks.manageTasks', type: 'breadDivButton', name: 'global.literals.manage', permissions: ['manage_tasks']}
	                    ];
                    }).catch(function(){
                    });
                    
                }
            });

            HeaderService.changeState($state.current);

            var daysColumn = new RemaingDaysGlobalColumn($filter, 'global.literals.days', HelperService, $rootScope.LoggedUser.entity.holidays);
            var userNameColumn =  new StrippedColumn($filter, 'global.literals.task_name', 100);
            var userStartedColumn = new UserColumn($filter, 'global.literals.created_for');
            var userCreateDateColumn = new DateColumn($filter, 'global.literals.creation_date');
            var userAssignedColumn = new UserColumn($filter,'global.literals.assign_to');
            var userStartDateColumn = new DateColumn($filter,'global.literals.startDate');
            var userExpiryDateColumn = new DateColumn($filter,'global.literals.endDate');
            var userActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.see','annexa.tasks.createdTasks.edit({ task: \'[data]\'})','fa-eye', true), [
                    new ActionButton('global.literals.rejectTask','rejectTask([data])', undefined, undefined, function (data, type, full, meta) {
                        if(!full.startDate && !full.endedTask && !full.endDate){
                            return true;
                        } else {
                            return false;
                        }
                    })
                ]);

            $scope.columnsAux = [
                {id: 'remainingDays', width: '40px', column:daysColumn },
                {id: 'description', column: userNameColumn, sortable: false },
                {id: 'createdUser', column: userStartedColumn},
                {id: 'createdDate', column: userCreateDateColumn},
                {id: 'user', column: userAssignedColumn},
                {id: 'taskComments', width: '100px', column: new CommentColumn($filter, 'global.literals.comments', 'fa-comment', 'comments', 'createdUser', true)},
                {id: 'startDate', column: userStartDateColumn},
                {id: 'endDate', column: userExpiryDateColumn}
            ];

            var datatableSettings = $rootScope.app.configuration.datatables_settings;
            $scope.columnsCreatedTasks = angular.copy($scope.columnsAux);
            $scope.columnsCreatedTasks.push({ id: 'actions', columnName: 'id', width: '170px', className: 'text-center', title: userActionsColumn.getTitle(), render: userActionsColumn.getRender, sortable: false});
            $scope.columnsCreatedTasks = getDatatableColumnsSettings(datatableSettings, 'datatable_task_created', $scope.columnsCreatedTasks);

            var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_task_created', $scope.columnsCreatedTasks);
            $scope.tableOrderProperties = {sortName: 'remainingDays', sort: [[0,'desc']]};
            if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > 0){
                $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
            }

            $scope.tableDefinition = {
                id: 'tableCreatedTasks',
                origin: 'common',
                objectType: 'TaskView',
                sortName: $scope.tableOrderProperties.sortName,
                sort: $scope.tableOrderProperties.sort,
                filterCall: $scope.getFilterCall(),
                filterCallAux: $scope.getFilterCallAux(),
                filterCallFunc: $scope.getFilterCall,
                filterCallAuxFunc: $scope.getFilterCallAux,
                columns: angular.copy($scope.columnsCreatedTasks),
                containerScope: $scope
            }

            $scope.rejectTask = function(data){
                if($scope.tableDefinition.list && $scope.tableDefinition.list.length > 0){
                    var aux = HelperService.findFirstElementWhere($scope.tableDefinition.list, 'id', data, -1);
                    var modal = angular.copy(globalModals.taskUpdateComment);
                    modal.title = 'global.literals.rejectTask';
                    modal.annexaFormly.model = {};
                    modal.annexaFormly.model.modal_body = {task:aux};
                    modal.annexaFormly.options = {};
                    modal.annexaFormly.options.formState = {readOnly: false};
                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.placeholder = $filter('translate')('global.literals.commentReject');
                    modal.annexaFormly.fields[0].fieldGroup[0].templateOptions.required = true;
                    var rejectTaskComplete = function(){
                        if(modal.annexaFormly.model.modal_body.task && modal.annexaFormly.model.modal_body.task.comments){
                            modal.annexaFormly.model.modal_body.task.endedTask = true;
                            modal.annexaFormly.model.modal_body.task.endDate = new Date();
                            modal.annexaFormly.model.modal_body.task.finishOk = false;

                            TaskFactory.updateTask(modal.annexaFormly.model.modal_body.task, './api/tasks/rejectTask').then(function(data){
                                $scope.tableDefinition.reloadInternalData(true, true);
                                modal.close();
                            }).catch(function(msg, error){
                                $scope.tableDefinition.reloadInternalData(false);
                                modal.close();
                            });
                        }
                    };
                    AnnexaFormlyFactory.showModal("modalUpdateComentTask", modal, rejectTaskComplete, false);
                }
            };

    }])
    .controller('AssignedTasksController',['$filter', '$state', '$scope','HeaderService', 'TaskFactory', 'api', '$stateParams', '$rootScope', 'DTColumnBuilder', 'HelperService', 'AnnexaFormlyFactory', 'globalModals', 'RouteStateFactory', 'TableFilterFactory', 'RestService',
        function($filter, $state, $scope, HeaderService, TaskFactory, api, $stateParams, $rootScope, DTColumnBuilder, HelperService, AnnexaFormlyFactory, globalModals, RouteStateFactory, TableFilterFactory, RestService) {
            $scope.filterData.assigned = true;
            $scope.filterData.manage = false;
            $scope.numCanceledTask = 0;
            $scope.tfilter = [
                { id: 'description', type: 'text', order: 0, label: 'global.literals.task_name' },
                { id: 'createdUser', type: 'text', order: 1, label: 'global.literals.started_for', callAux: true},
                { id: 'user', type: 'text', order: 2, label: 'global.literals.assign_to', callAux: true},
                { id: 'startDates', type: 'dateRange', order: 3, label: 'global.literals.startDate', callAux: true},
                { id: 'endDates', type: 'dateRange', order: 4, label: 'global.literals.endDate', callAux: true},
                { id: 'expired', type: 'select', order: 5, label: 'global.literals.expiredSate', dataType: 'LOCAL', data: angular.copy($scope.expiredStates), addAll: true, nameProperty: 'name', callAux: true},
                { id: 'state', type: 'select', order: 6, label: 'global.literals.state', dataType: 'LOCAL', data: angular.copy($scope.taskStates), addAll: true, nameProperty: 'name', callAux: true, preFilter: 'IN_PROGRESS_AND_PENDING' }
            ];

            var routeState = RouteStateFactory.getRouteState($state.current);
            if(routeState) {
                $scope.tfilter = routeState.state;
            }
            if($stateParams.type){
                var filterState = $linq($scope.tfilter).where("x => x.id == 'state'").toArray();
                var filterExpired= $linq($scope.tfilter).where("x => x.id == 'expired'").toArray();
                if($stateParams.type == 'A' && filterState) {
                    filterState[0].model = { id: 'IN_PROGRESS_AND_PENDING', name: $filter('translate')('IN_PROGRESS_AND_PENDING') };
                }else if($stateParams.type == 'AE' && filterState && filterExpired){
                    filterExpired[0].model = { id: 'expired', name: $filter('translate')('global.literals.outOfTime') };
                    filterState[0].model = { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') };
                }else if($stateParams.type == 'ANE' && filterState && filterExpired){
                    filterExpired[0].model = { id: 'noExpired', name: $filter('translate')('global.literals.tramNoExpired') };
                    filterState[0].model = { id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS') };
                }else if($stateParams.type == 'AP' && filterState){
                    filterState[0].model = { id: 'PENDING', name: $filter('translate')('PENDING') };
                }else if($stateParams.type == 'AF' && filterState){
                    filterState[0].model = { id: 'FINISHED', name: $filter('translate')('FINISHED') };
                }
            }

            $scope.getFilterCall = function() {
                var filterCall = TableFilterFactory.getFilterCall($scope.tfilter);
                return filterCall;
            };

            $scope.getFilterCallAux = function(){
                var filterCallAux = TableFilterFactory.getFilterCallAux($scope.tfilter);
                filterCallAux.loggedUser = $rootScope.LoggedUser.id;
                filterCallAux.assigned = $scope.filterData.assigned;
                filterCallAux.manage = $scope.filterData.manage;

                if(filterCallAux.expired){
                    if(filterCallAux.expired.id == 'expired'){
                        filterCallAux.expired = true;
                    }else if(filterCallAux.expired.id == 'noExpired'){
                        filterCallAux.expired = false;
                    }else{
                        filterCallAux.expired = undefined;
                    }
                }
                if(filterCallAux.state){
                    if(filterCallAux.state.id){
                        filterCallAux.state = filterCallAux.state.id;
                    }else{
                        filterCallAux.state = '';
                    }
                }
                return filterCallAux;
            };

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.tasks.assignedTasks') {
                    $rootScope.headButtons = [
                     new HeadButton('btn primary breadDivButton mb-xs-pt', undefined, '#dataTableTask','fa-plus', 'global.literals.new_task_title', undefined, 'newTask').setPermissions('create_task')
                     ];
                    $rootScope.subHeadButtons = [new HeadButtonSearch('#tableFilter')];
                    
                    RestService.findByUrl("./api/tasks/countCanceledTask" + "?createdUser=" + $rootScope.LoggedUser.id + "&endedTask=true").then(function(data){
                    	$scope.numCanceledTask = 0;
                    	if (data && data.data > 0) {
                    		$scope.numCanceledTask = data.data;
                    	}
	                    $rootScope.subHeadTabs = [
	                        {state:'annexa.tasks.assignedTasks', type:'breadDivButton active', name:'global.literals.tasksAssigned'},
	                        {state:'annexa.tasks.createdTasks', type:'breadDivButton', name:'global.literals.tasksCreated', permissions: ['create_task']},
	                        {state: 'annexa.tasks.manageTasks', type: 'breadDivButton', name: 'global.literals.manage', permissions: ['manage_tasks']}
	                    ];
                    }).catch(function(){
                    });  
                    
                }
            });

            HeaderService.changeState($state.current);

            var daysColumn = new RemaingDaysGlobalColumn($filter, 'global.literals.days', HelperService, $rootScope.LoggedUser.entity.holidays);
            var userNameColumn = new StrippedColumn($filter, 'global.literals.task_name', 100);
            var userStartedColumn = new UserColumn($filter, 'global.literals.created_for');
            var userCreateDateColumn = new DateColumn($filter, 'global.literals.creation_date');
            var userAssignedColumn = new UserColumn($filter,'global.literals.assign_to');
            var userStartedDateColumn = new DateColumn($filter,'global.literals.startDate');
            var userExpiryDateColumn = new DateColumn($filter,'global.literals.endDate');
            var userActionsColumn = new ActionsColumn($filter('translate')('global.literals.actions'),
                new ActionButton('global.literals.see','annexa.tasks.assignedTasks.edit({ task: \'[data]\'})','fa-eye', true),
                [
                    new ActionButton('global.literals.initTask','initTask([data])', undefined, undefined, function (data, type, full, meta) {
                        if(!full.startDate && !full.endedTask && !full.endDate){
                            return true;
                        } else {
                            return false;
                        }
                    }),
                    new ActionButton('global.literals.rejectTask','rejectTask([data])', undefined, undefined, function (data, type, full, meta) {
                        if(!full.startDate && !full.endedTask && !full.endDate){
                            return true;
                        } else {
                            return false;
                        }
                    }),
                    new ActionButton('global.literals.finishTask','finishTask([data])', undefined, undefined, function (data, type, full, meta) {
                        if(full.startDate && !full.endedTask && !full.endDate)
                        {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                    new ActionButton('global.literals.reassignTask', 'reassignTask([data])', undefined, undefined, function (data, type, full, meta) {
                        if(full.startDate && !full.endedTask && !full.endDate){
                            return true;
                        } else {
                            return false;
                        }
                    })
                ]);

            $scope.columnsAux = [
                {id: 'remainingDays', width: '40px', column:daysColumn },
                {id: 'description', column: userNameColumn, sortable: false },
                {id: 'createdUser', column: userStartedColumn},
                {id: 'createdDate', column: userCreateDateColumn},
                {id: 'user', column: userAssignedColumn},
                {id: 'taskComments', width: '100px', column: new CommentColumn($filter, 'global.literals.comments', 'fa-comment', 'comments', 'createdUser', true)},
                {id: 'startDate', column: userStartedDateColumn},
                {id: 'endDate', column: userExpiryDateColumn}
            ];

            var datatableSettings = $rootScope.app.configuration.datatables_settings;
            $scope.columnsAssignedTasks = angular.copy($scope.columnsAux);
            $scope.columnsAssignedTasks.push({ id: 'actions', columnName: 'id', width: '170px', className: 'text-center', title: userActionsColumn.getTitle(), render: userActionsColumn.getRender, sortable: false});
            $scope.columnsAssignedTasks = getDatatableColumnsSettings(datatableSettings, 'datatable_tasks_assigned', $scope.columnsAssignedTasks);

            var orderProperties = getDatatableGetColumnOrderProperties(datatableSettings, 'datatable_tasks_assigned', $scope.columnsAssignedTasks);
            $scope.tableOrderProperties = {sortName: 'remainingDays', sort: [[0,'desc']]};
            if(orderProperties && orderProperties.name && orderProperties.direction && orderProperties.index > 0){
                $scope.tableOrderProperties = {sortName: orderProperties.name, sort: [[orderProperties.index,orderProperties.direction]]};
            }

            $scope.tableDefinition = {
                id: 'tableAssignedTasks',
                origin: 'common',
                objectType: 'TaskView',
                sortName: $scope.tableOrderProperties.sortName,
                sort: $scope.tableOrderProperties.sort,
                filterCall: $scope.getFilterCall(),
                filterCallAux: $scope.getFilterCallAux(),
                filterCallFunc: $scope.getFilterCall,
                filterCallAuxFunc: $scope.getFilterCallAux,
                columns: angular.copy($scope.columnsAssignedTasks),
                containerScope: $scope
            }

            $scope.initTask = function(data){
                if($scope.tableDefinition.list && $scope.tableDefinition.list.length > 0){
                    var aux = HelperService.findFirstElementWhere($scope.tableDefinition.list, 'id', data, -1);
                    TaskFactory.initTask(aux, HeaderService, $state, $scope.tableDefinition);
                }
            };
            $scope.rejectTask = function(data){
                if($scope.tableDefinition.list && $scope.tableDefinition.list.length > 0){
                    var aux = HelperService.findFirstElementWhere($scope.tableDefinition.list, 'id', data, -1);
                    TaskFactory.rejectTask(aux, HeaderService, $state, $scope.tableDefinition);
                }
            };
            $scope.finishTask = function(data){
                if($scope.tableDefinition.list && $scope.tableDefinition.list.length > 0){
                    var aux = HelperService.findFirstElementWhere($scope.tableDefinition.list, 'id', data, -1);
                    TaskFactory.finishTask(aux, HeaderService, $state, $scope.tableDefinition);
                }
            };

            $scope.reassignTask = function(data)
            {
                var modal = angular.copy(globalModals.reassignTask);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = {readOnly: false};
                var reassignTaskComplete = function () {
                    var model = modal.annexaFormly.model.modal_body;

                    var taskComment = {
                        comments: model.comments,
                        createdUser: $rootScope.LoggedUser,
                        createdDate: new Date(),
                        task: {id: data}
                    };

                    TaskFactory.reassignTask(taskComment, model.newUser.id, data).then(function (data) {
                        modal.close();
                        $state.go('annexa.tasks');
                    }).catch(function (error) {
                        modal.alerts.push({msg: 'global.task.reassignError'});
                    });
                };
                AnnexaFormlyFactory.showModal("modalReassignTask", modal, reassignTaskComplete, false);


            };
    }])
    .controller('NewTasksController',['$filter', '$state', '$scope','HeaderService', 'TaskFactory', 'AnnexaEntityFactory', 'api', '$stateParams', '$rootScope', 'DTColumnBuilder', 'HelperService', 'AnnexaFormlyFactory', 'globalModals', 'DialogsFactory',
    function($filter, $state, $scope, HeaderService, TaskFactory, AnnexaEntityFactory, api, $stateParams, $rootScope, DTColumnBuilder, HelperService, AnnexaFormlyFactory, globalModals, DialogsFactory) {
        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.tasks.createdTasks.new') {
                $rootScope.subHeadButtons = [
                    new HeadButton('btn grey-500 text-white breadDivButton m-r-sm', undefined, '#new-task',undefined,'global.literals.cancel',undefined,'cancelEntity'),
                    new HeadButton('btn primary breadDivButton m-r-sm',undefined,'#new-task','fa-floppy-o','global.literals.save',undefined,'saveEntity')
                ];
            }
        });

        HeaderService.changeState($state.current);

        $scope.form = AnnexaEntityFactory.newTaskForm('annexa.tasks.createdTasks');
        $scope.boxes = AnnexaEntityFactory.taskBoxes();
        $scope.cancelState = 'annexa.tasks.createdTasks';
        $scope.saveEntity = function(){
            if(this.boxes[0].boxDefinition.content.length == 0 && $rootScope.esetMode) {
                DialogsFactory.error('global.task.noComments', 'Error')
            } else {
                $rootScope.$broadcast('saveEntityComponent');
            }
        }

        $scope.cancelEntity = function(){
            $rootScope.$broadcast('cancelEntityComponent');
        }
    }])
    .controller('EditTasksController',['$filter', '$state', '$scope','HeaderService', 'TaskFactory', 'AnnexaEntityFactory', 'api', '$stateParams', '$rootScope', 'DTColumnBuilder', 'AnnexaFormlyFactory', 'globalModals', 'HelperService', 'GlobalDataFactory',
        function($filter, $state, $scope, HeaderService, TaskFactory, AnnexaEntityFactory, api, $stateParams, $rootScope, DTColumnBuilder, AnnexaFormlyFactory, globalModals, HelperService, GlobalDataFactory) {
            $scope.task = TaskFactory.task;
            $scope.userAux = TaskFactory.userAux;
            $scope.createdUserAux = TaskFactory.createdUserAux;
            $scope.isCreator = TaskFactory.isCreator;
            $scope.isAssigned = TaskFactory.isAssigned;
            $scope.esetMode = $rootScope.esetMode;

            //$scope.thirdModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.thirds'").toArray();
            $scope.docModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.doc.documents.all'").toArray();
            //$scope.secModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.sec'").toArray();
            $scope.dossierModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tram'").toArray();
            $scope.regInputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.input'").toArray();
            $scope.regOutputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.output'").toArray();
            $scope.tasksModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.tasks'").toArray();


            if($rootScope.esetMode) {
                if($rootScope.LoggedUser && $rootScope.LoggedUser.entity && $rootScope.LoggedUser.entity.holidays){
                    $scope.task.taskEndDate = HelperService.getExpirationDate(new Date($scope.task.createdDate),$scope.task.days, $rootScope.LoggedUser.entity.holidays);
                }else{
                    $scope.task.taskEndDate = HelperService.getExpirationDate(new Date($scope.task.createdDate),$scope.task.days, undefined);
                }
            }

            HeaderService.onChangeState($scope, function (message) {
                if(message.state.name == 'annexa.tasks.createdTasks.edit' || message.state.name == 'annexa.tasks.assignedTasks.edit') {
                    $rootScope.subHeadButtons = [];

                    var taskOpened = false;

                    if($scope.task && !$scope.task.startDate && !$scope.task.endedTask && !$scope.task.endDate) {
                        if ($scope.isAssigned || $scope.isCreator) {
                            $rootScope.subHeadButtons.push(new HeadButton('btn danger  breadDivButton ml-sm-pt ', undefined, '#edit-task', 'fa fa-minus-circle', 'global.literals.rejectTask', undefined, 'rejectTask'));
                            taskOpened = true;
                        }
                        if ($scope.isAssigned) {
                            $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt ', undefined, '#edit-task', 'fa fa-check-square-o', 'global.literals.initTask', undefined, 'initTask'));
                            taskOpened = true;
                        }
                    }else if($scope.task && $scope.task.startDate && !$scope.task.endedTask && !$scope.task.endDate){
                        if ($scope.isAssigned) {
                            $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt ', undefined, '#edit-task', '', 'global.literals.finishTask', undefined, 'finishTask'));
                            taskOpened = true;
                        }
                    }

                    if(taskOpened) {
                        $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-task', 'fa fa-exchange', 'global.literals.reassignTask', undefined, 'reassignTask'));
                    }else{
                        if($scope.task && $scope.task.endedTask && $scope.task.endDate && ($scope.isAssigned || $scope.isCreator)) {
                            $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-task', '', 'global.task.reopen', undefined, 'reopenTask'));
                        }
                    }

                    $rootScope.subHeadButtons.push(new HeadButton('btn primary breadDivButton ml-sm-pt', undefined, '#edit-task', 'fa fa-eye', 'global.documents.new.audit', undefined, 'auditTask').setPermissions('admin_audit'));
                /*} else if (message.state.name == 'annexa.tasks.manageTasks.edit') {
                    $rootScope.subHeadButtons = [
                        new HeadButton('btn grey-500 text-white breadDivButton m-r-sm ', undefined, '#edit-task','fa fa-chevron-left','global.literals.back',undefined,'cancel')
                    ];*/
                }
            });

            HeaderService.changeState($state.current);
            var isOpen = true;
            if($scope.task.endedTask || $scope.task.endDate) isOpen = false;

            $scope.isManage = false;
            if($state.current.name == 'annexa.tasks.manageTasks.edit') {
                $scope.isManage = true;
            }

            $scope.dossierTransactionBox = AnnexaEntityFactory.getEditTaskBox('dossierTransaction',isOpen && !$scope.isManage);
            $scope.dossierTransactionBox.boxDefinition.isManage = $scope.isManage;
            $scope.dossierTransactionBox.boxDefinition.isOpenTask = isOpen;
            $scope.registerInputBox = AnnexaEntityFactory.getEditTaskBox('registerEntry', isOpen && !$scope.isManage, 'INPUT');
            $scope.registerInputBox.boxDefinition.isManage = $scope.isManage;
            $scope.registerInputBox.boxDefinition.isOpenTask = isOpen;
            $scope.registerOutputBox = AnnexaEntityFactory.getEditTaskBox('registerEntry', isOpen && !$scope.isManage, 'OUTPUT');
            $scope.registerOutputBox.boxDefinition.isManage = $scope.isManage;
            $scope.registerOutputBox.boxDefinition.isOpenTask = isOpen;
            $scope.documentBox = AnnexaEntityFactory.getEditTaskBox('document', isOpen && !$scope.isManage);
            $scope.documentBox.boxDefinition.isManage = $scope.isManage;
            $scope.documentBox.boxDefinition.isOpenTask = isOpen;
            $scope.commentBox = AnnexaEntityFactory.getEditTaskBox('comment', isOpen && !$scope.isManage);
            $scope.commentBox.boxDefinition.isManage = $scope.isManage;
            $scope.commentBox.boxDefinition.isOpenTask = isOpen;
            $scope.attachmentBox = AnnexaEntityFactory.getEditTaskBox('attachment', isOpen && !$scope.isManage);
            $scope.attachmentBox.boxDefinition.isManage = $scope.isManage;
            $scope.attachmentBox.boxDefinition.isOpenTask = isOpen;
            $scope.taskBox = AnnexaEntityFactory.getEditTaskBox('task', isOpen && !$scope.isManage);
            $scope.taskBox.boxDefinition.isManage = $scope.isManage;
            $scope.taskBox.boxDefinition.isOpenTask = isOpen;

            $scope.dossierTransactionBox.boxDefinition.content = $scope.task.dossierTransactions;
            $scope.registerInputBox.boxDefinition.content = $scope.task.registerEntryInputs;
            $scope.registerOutputBox.boxDefinition.content = $scope.task.registerEntryOutputs;
            $scope.documentBox.boxDefinition.content = $scope.task.documents;
            $scope.attachmentBox.boxDefinition.content = $scope.task.attachments;
            $scope.attachmentBox.boxDefinition.task = $scope.task;
            $scope.attachmentBox.boxDefinition.viewOrDownloadDocument = TaskFactory.downloadAttach;
            if($scope.task.relatedTasks){
                _.forEach($scope.task.relatedTasks, function (value) {
                    var taskAux = angular.copy(value.relatedTaskInit);
                    taskAux.idOriginal = value.id;
                    $scope.taskBox.boxDefinition.content.push(taskAux);
                });
            }
            $scope.commentBox.boxDefinition.task = $scope.task;
            if($scope.task.taskComments){
                var commentsTask = $linq($scope.task.taskComments).orderBy("x => x.createdDate").toArray();
                $scope.task.taskComments.length = 0;
                _.forEach(commentsTask, function(value){
                    $scope.task.taskComments.push(value);
                });

            }
            $scope.commentBox.boxDefinition.content = $scope.task.taskComments;

            $scope.updateTask = function(val, prop, close, modal){
                var taskAux = angular.copy($scope.task);
                if(prop) {
                    if(prop == 'user') {
                        if(val && val.user && val.user.id){
                            taskAux[prop] = {id:val.user.id};
                        }else{
                            return $filter('translate')('global.validation.required');
                        }
                    } else if(prop == 'taskEndDate') {
                        if($rootScope.LoggedUser && $rootScope.LoggedUser.entity && $rootScope.LoggedUser.entity.holidays) {
                            taskAux.days = HelperService.getWorkingDays(new Date(taskAux.createdDate), val, $rootScope.LoggedUser.entity.holidays);
                        }else{
                            taskAux.days = HelperService.getWorkingDays(new Date(taskAux.createdDate), val, undefined);
                        }
                    } else {
                        if(val){
                            taskAux[prop] = val;
                        }else{
                            return $filter('translate')('global.validation.required');
                        }
                    }
                    TaskFactory.updateTask(taskAux).then(function (data) {
                       if(close) {
                           modal.close();
                           $state.go('annexa.tasks');
                       }
                    }).catch(function (error) {
                       var a = 0;
                    });
                };
            };

            $scope.cancel = function () {
                if($state.current.name == 'annexa.tasks.createdTasks.edit'){
                    $state.transitionTo('annexa.tasks.createdTasks');
                }else{
                    $state.transitionTo('annexa.tasks.assignedTasks');
                }
            }

            $scope.initTask = function () {
                TaskFactory.initTask($scope.task, HeaderService, $state);
            }

            $scope.rejectTask = function () {
                TaskFactory.rejectTask($scope.task, HeaderService, $state);
            }

            $scope.finishTask = function () {
                TaskFactory.finishTask($scope.task, HeaderService, $state);
            }

            $scope.auditTask = function () {
                if ($scope.task && $scope.task.id) {
                    window.open($state.href('annexa.admin.audit_task', {"objectId": $scope.task.id }), '_blank');
                }
            };
            $scope.reassignTask = function() {
                var modal = angular.copy(globalModals.reassignTask);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = { readOnly: false };
                var reassignTaskComplete = function(){
                    var model = modal.annexaFormly.model.modal_body;

                    var taskComment = {
                        comments: model.comments,
                        createdUser: $rootScope.LoggedUser,
                        createdDate: new Date(),
                        task: { id: $scope.task.id }
                    };

                    TaskFactory.reassignTask(taskComment, model.newUser.id, $scope.task.id).then(function(data) {
                            modal.close();
                            $state.go('annexa.tasks');
                        }).catch(function (error) {
                        modal.alerts.push({msg:'global.task.reassignError'});
                    });
                };
                AnnexaFormlyFactory.showModal("modalReassignTask", modal, reassignTaskComplete, false);
            }

            $scope.reopenTask = function() {
                var modal = angular.copy(globalModals.reopenTask);
                modal.annexaFormly.model = {};
                modal.annexaFormly.model.modal_body = {};
                modal.annexaFormly.options = {};
                modal.annexaFormly.options.formState = { readOnly: false };
                var reopenTaskComplete = function(){
                    var model = modal.annexaFormly.model.modal_body;

                    var taskComment = {
                        comments: model.comments,
                        createdUser: $rootScope.LoggedUser,
                        createdDate: new Date(),
                        task: { id: $scope.task.id }
                    };

                    TaskFactory.reopenTask(taskComment).then(function(data) {
                        modal.close();
                        $scope.cancel();
                    }).catch(function (error) {
                        modal.alerts.push({msg:'global.task.reopenError'});
                    });
                };
                AnnexaFormlyFactory.showModal("modalReopenTask", modal, reopenTaskComplete, false);
            }

        }]);