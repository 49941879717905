/**
 * Created by osirvent on 01/02/2016.
 */
angular
    .module('annexaApp')
    .controller('ProfileController',['$scope', '$rootScope', '$stateParams','$http', 'CommonAdminService','$state', 'apiAdmin','apiSign', 'SignService', '$translate', '$translatePartialLoader', 'Language', 'LoginService', 'LocalDataFactory', 'HelperService', 'HeaderService', 'RestService', 'tmhDynamicLocale', 'globalModals', 'AnnexaFormlyFactory', 'CommonService', 'AdminFactory', 'DialogsFactory', '$filter',
    function($scope, $rootScope, $stateParams, $http, CommonAdminService, $state,  apiAdmin, apiSign, SignService, $translate, $translatePartialLoader, Language, LoginService, LocalDataFactory, HelperService, HeaderService, RestService, tmhDynamicLocale, globalModals, AnnexaFormlyFactory, CommonService, AdminFactory, DialogsFactory, $filter) {
        $scope.isLoggedUser = true;
        $scope.language = {languageSelect:null, languages:[]};
        $scope.languageColumn = Language.getActiveColumn();
        HeaderService.onChangeState($scope, function (message) {
            if(message.state.name == 'annexa.profile'){
                $scope.getMyDelegations();
            }
        });

        $scope.logout = function(){
            LoginService.LogOut($scope);
        };

        $scope.changePassword = function () {
            var modal = globalModals.changePassword;
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false };
            modal.alerts = [];

            AnnexaFormlyFactory.showModal('modalChangePassword', modal, $scope.changePasswordSubmit, false);
        }

        var changePasswordError = function(modal) {
            modal.alerts.push({ msg: 'global.errors.changePasswordError' });
        }

        $scope.changePasswordSubmit = function() {
            if(this.annexaFormly.form.$valid) {
                var modal = this;
                var model = modal.annexaFormly.model.modal_body;

                $http({
                    url: './api/users/check_password/' + $scope.userProfile.id,
                    method: 'GET',
                    params:{
                        password:model.oldPassword
                    }
                }).then(function(data) {
                    if(data.data) {
                        if(model.newPassword != model.rePassword) {
                            changePasswordError(modal);
                        } else {
                            $http({
                                url: './api/users/change_password/' + $scope.userProfile.id,
                                method: 'PUT',
                                data: model.newPassword
                            }).then(function (data) {
                                if(data.data) {
                                    modal.close();
                                    $scope.logout();
                                } else {
                                    changePasswordError(modal);
                                }
                            }).catch(function (error) {
                                changePasswordError(modal)
                            })
                        }
                    } else {
                        changePasswordError(modal);
                    }
                }).catch(function(error) {
                    changePasswordError(modal);
                })
            }
        }

        $scope.showChangePassword = function() {
            var show = false;
            if(!$stateParams.idUser){
                if($rootScope.app.configuration.security_method.value == 'DATABASE' || $rootScope.app.configuration.security_method.value == 'LDAPANDDATABASE' ){
                    if($rootScope.app && $rootScope.app.loggeduser && $rootScope.app.loggeduser.password){
                        show = true;
                    }
                }
            }
            return show;
        }

        $scope.showResetCache = function() {
            var show = false;
            if(localStorage && localStorage.clear){
                show = true;
            }
            if(sessionStorage && sessionStorage.clear){
                show = true;
            }
            return show;
        }


        $scope.resetCache = function() {
            DialogsFactory.confirm('global.literals.resetCache', $filter('translate')('global.literals.confirmResetCache')).then(function (data) {
                if(localStorage && localStorage.clear){
                    localStorage.clear();
                }
                if(sessionStorage && sessionStorage.clear){
                    sessionStorage.clear();
                }
                LoginService.LogOut($scope);
            }).catch(function () {
            });
        }

        //language
        LocalDataFactory.getAllData(["languages"]).then(function(data){
            $scope.language.languages = HelperService.getArray(data,'name', 'languages');
            for(var i=0; i<$scope.language.languages.length; i++) {
                if($scope.language.languages[i].acronym == $rootScope.app.language) {
                    $scope.language.languageSelect = $scope.language.languages[i];
                }
            }
        }).catch(function(a){});

        //recullo delegacions meves//
        $scope.getMyDelegations = function() {
            $scope.delgacioFeta = LoginService.getActiveDelegation(true);
            $scope.active = false
            if ($scope.delgacioFeta) {
            	if ($scope.delgacioFeta.startDelegationDate.time && new Date($scope.delgacioFeta.startDelegationDate.time) <= new Date()) {
            		$scope.active = true;
            	} else if ($scope.delgacioFeta.startDelegationDate && new Date($scope.delgacioFeta.startDelegationDate) <= new Date()) {
            		$scope.active = true;
            	}
            }
        };


        if($stateParams.idUser != null && $stateParams.idUser != undefined && $stateParams.idUser != '' && $stateParams.idUser != $rootScope.LoggedUser.identifier){
            $scope.isLoggedUser = false;

            RestService.findByUrl('./api/users/findByIdentifier?identifier='+ $stateParams.idUser).then(function(response) {
                if(response && response.data){
                    $scope.userProfile = JSOG.decode(response.data);
                    if($scope.userProfile && $scope.userProfile.userSections){
                        $scope.userProfile.sections = $scope.userProfile.userSections;
                    }
                }else{
                    $scope.userProfile = {};
                }
            }).catch(function (response) {
            });

        }
        else{
            $scope.userProfile = $rootScope.app.loggeduser;

            $http({
                url: './api/users/findSectionsByUser',
                method: 'GET',
                params: {
                    user: $scope.userProfile.id
                }
            }).then(function (response) {
                $scope.userProfile.sections = JSOG.decode(response.data);
            }).catch(function (response) {
            });

            // con este se visualizara los perfiles predeterminados
            $scope.defaultProfiles = [
                { id: 'REG', description: 'global.commonAdmin.modal.defaultEntityProfile.REG', profile: undefined, originId: undefined},
                { id: 'DOC', description: 'global.commonAdmin.modal.defaultEntityProfile.DOC', profile: undefined, originId: undefined},
                { id: 'EXP', description: 'global.commonAdmin.modal.defaultEntityProfile.EXP', profile: undefined, originId: undefined},
                { id: 'SEC', description: 'global.commonAdmin.modal.defaultEntityProfile.SEC', profile: undefined, originId: undefined}
            ];

            _.forEach($scope.defaultProfiles, function(item, index) {
                var dProfile = $linq($scope.userProfile.userDefaultEntityProfiles).singleOrDefault(undefined, "x => x.entityType == '" + item.id + "'");

                if(dProfile) {
                    $scope.defaultProfiles[index].profile = dProfile.profile;
                    $scope.defaultProfiles[index].originId = dProfile.id;
                }
            })

            $scope.isLoggedUser = true;
            $scope.getMyDelegations();
        };

        $scope.changeDefaultProfile = function (dp, select) {
            var selectedProfile = $linq($scope.userProfile.userProfiles).singleOrDefault(undefined, "x => x.profile.id == " + dp.profile);

            if(selectedProfile) {
                select.selected = selectedProfile.profile;

                var selectedDEP = $linq($scope.userProfile.userDefaultEntityProfiles).singleOrDefault(undefined, "x => x.id == " + dp.originId);

                if(selectedDEP) {
                    selectedDEP.profile = { id: dp.profile };
                    selectedDEP.user = { id: $scope.userProfile.id};

                    RestService.update('./api/defaul_entity_profile/' + selectedDEP.id, selectedDEP)

                        .then(function(data) {
                            //Empty
                        }).catch(function(error) {
                            //Empty
                    });
                } else {
                    selectedDEP = {
                        entityType: dp.id,
                        profile: { id: dp.profile },
                        user: { id: $scope.userProfile.id }
                    };

                    RestService.insert('./api/defaul_entity_profile', selectedDEP)
                        .then(function(data) {
                            //Empty
                        }).catch(function(error) {
                        //Empty
                    });
                }
            }
        }

        $scope.changeLanguage = function() {
            if($scope.language.languageSelect != null && $scope.language.languageSelect != undefined){
                $scope.showLoadingdiv = true;
                var content = {
                    id:$scope.userProfile.id,
                    language:apiAdmin.getLanguages+'/'+$scope.language.languageSelect.id
                };
                CommonAdminService.updateObject(apiAdmin.getUsers,$scope.userProfile.id,content).then(function (data) {
                    $translate.preferredLanguage($scope.language.languageSelect.acronym);
                    $translate.use($scope.language.languageSelect.acronym).then(function(){
                        $translate.refresh();
                    });
                    tmhDynamicLocale.set($scope.language.languageSelect.acronym);
                    LoginService.ChangeLanguage($scope.language.languageSelect);
                    $rootScope.app.language = $scope.language.languageSelect.acronym;
                    Language.setCurrent($scope.language.languageSelect.acronym);
                    $rootScope.app.langColumn = Language.getActiveColumn();
                    $scope.languageColumn = Language.getActiveColumn();
                    $scope.showLoadingdiv = false;
                }).catch(function(msg){
                    $scope.showLoadingdiv = false;
                });
            }
        };

        $scope.replacePhotoModal = function () {
            var modal = globalModals.uploadFile;
            modal.title = 'global.literals.replace';
            modal.annexaFormly.model = {};
            modal.annexaFormly.model.modal_body = {};
            modal.annexaFormly.options = {};
            modal.annexaFormly.options.formState = { readOnly: false};
            modal.user = $scope.userProfile;
            modal.annexaFormly.fields[0].fieldGroup[0].data.accept = '\'image/gif, image/jpeg, image/png\'';
            AnnexaFormlyFactory.showModal('modalUploadPhoto', modal, $scope.replacePhoto, false);
        }

        $scope.replacePhoto = function () {
            if(this.annexaFormly.form.$valid) {
                var self = this;
                var fileToAdd = {fileName: this.annexaFormly.model.modal_body.file.name, fileType: this.annexaFormly.model.modal_body.file.type };
                var reader = new FileReader();
                reader.onload = function (e) {
                    fileToAdd.file = CommonService.base64ArrayBuffer(e.target.result);
                    AdminFactory.updatePhotoUser(self.user, fileToAdd).then(function (data){
                        if(self.user){
                            self.user.photo = JSOG.decode(data.data).value;
                        }
                        self.close();
                    }).catch(function (error) {
                        DialogsFactory.error($filter('translate')('global.errors.errorModifyProfilePhoto'), $filter('translate')('DIALOGS_ERROR_MSG'));
                        self.close();
                    });

                }
                reader.readAsArrayBuffer(this.annexaFormly.model.modal_body.file);
            }
        }
        
        $scope.changeUserMailAlert = function(userAlertTypeEnum,status) {
        	if(($scope.userMailAlert.documentsToSignOrValidate != null && $scope.userMailAlert.documentsToSignOrValidate != undefined) ||
        			($scope.userMailAlert.diligenceChanges != null && $scope.userMailAlert.diligenceChanges != undefined) ||
        			($scope.userMailAlert.dossierChanges != null && $scope.userMailAlert.dossierChanges != undefined) ||
        			($scope.userMailAlert.proposalChanges != null && $scope.userMailAlert.proposalChanges != undefined) ||
        			($scope.userMailAlert.taskChanges != null && $scope.userMailAlert.taskChanges != undefined)){
        		CommonAdminService.updateUserAlertMail(userAlertTypeEnum,status,this.userProfile.id);
        	}
        };

        /*si se agregan nuevos avisos, agregar en los case los avisos tal cual se definen en UserAlertTypeEnum y relacionarlos con los checkbox*/
    	CommonAdminService.getUserMailAlerts($scope.userProfile.id).then(function (data){
    		var userMailAlerts = data;
    		if(userMailAlerts){
    			$scope.userMailAlert = {};
    			
	        	for(var i=0; i<userMailAlerts.length; i++) {
	        		var alert = userMailAlerts[i].alertType;
	        		switch (alert){
	        			case 'DOCUMENT_SIGNATURE':
	        				$scope.userMailAlert.documentsToSignOrValidate = true;
	        				break;
	        			case 'DILIGENCE':
	        				$scope.userMailAlert.diligenceChanges = true;
	        				break;
	        			case 'DOSSIER':
	        				$scope.userMailAlert.dossierChanges = true;
	        				break;
	        			case 'PROPOSAL':
	        				$scope.userMailAlert.proposalChanges = true;
	        				break;
	        			case 'TASK':
	        				$scope.userMailAlert.taskChanges = true;
	        				break;
	        		}
	        	}
    		}
        		
        }).catch(function (error) {
           DialogsFactory.error($filter('translate')('global.errors.errorSetUserMailAlert'), $filter('translate')('DIALOGS_ERROR_MSG'));
            self.close();
        });	
    
    
    }]);