/**
 * Created by osirvent on 25/01/2016.
 */
angular.module('annexaApp')
    .controller('SendMailController',['$scope','$uibModalInstance', '$translate', 'doc', 'EmailFactory','DccumentsFactory',  function($scope, $uibModalInstance, $translate, doc, EmailFactory, DccumentsFactory) {
        $scope.doc = doc;
        DccumentsFactory.getAttachments($scope.doc.idDoc).then(function(data) {
            $scope.doc.docnameattachments.length = 0;
            if(data){
                _.forEach(data, function(attach){
                    $scope.doc.docnameattachments.push(attach);
                });
            }
        }).catch(function(error) {
        });
        $scope.alerts = [];
        $scope.emailFields = [
            {
                key: 'to',
                type: 'annexaInput',
                templateOptions: {
                    type: 'email',
                    label: $translate.instant('global.email.to'),
                    required: true
                }
            },
            {
                key: 'subject',
                type: 'annexaInput',
                templateOptions: {
                    type: 'text',
                    label: $translate.instant('global.literals.subject'),
                    translate: 'global.subject',
                    required: true
                }
            },
            {
                key: 'text',
                type: 'annexaTextArea',
                templateOptions: {
                    label: 'Text',
                    translate: $translate.instant('global.literals.text'),
                    rows: 5,
                    required: true
                }
            },
            {
                key: 'docName',
                type: 'annexaLabelRow',
                templateOptions: {
                    label: $translate.instant('global.email.attachment'),
                    value:doc.docname
                },
                data: {
                    informed:true,
                    row:true,
                    breakLine: true,
                    colClass: ' col-sm-12',
                    labelClass: 'label-strong'
                }
            },
            {
                key: 'documents',
                type: 'annexaRadioCheckboxRow',
                className: 'col-sm-12 daughter-label-strong',
                data: {
                    informed: true,
                    row: true
                },
                templateOptions: {
                    type: 'checkHTMLOneModelMail',
                    optionsAttr: 'bs-options',
                    required: false,
                    validate: true,
                    options: doc.docnameattachments,
                    ngOptions:'option[to.valueProp] as option in to.options',
                    valueProp: 'id',
                    labelProp: 'fileName'
                }
            }
        ];

        $scope.email = {
            to: '',
            subject: '',
            text: ''
        }


        $scope.ok = function() {
            if(this.emailForm.$valid) {
                var loading = this;
                loading.$parent.showLoadingdivSignin = true;
                var attachments = [];
                if(this.email.documents){
                   attachments = $linq(Object.keys(this.email.documents)).where(function(x){
                        if(loading.email.documents[x]){
                            return true;
                        }else{
                            return false;
                        }
                    }).toArray();
                }
                EmailFactory.sendEmail(this.email.to,this.email.subject,this.email.text,this.doc.docid,this.doc.docname,this.doc.idUser,this.doc.idDoc, attachments)
                    .then(function(data) {
                        loading.$parent.showLoadingdivSignin = false;
                        $uibModalInstance.close();
                    }, function (error) {
                        if(error && error.data && error.data.message == 'Mail max size exceded not found') {
                            $scope.alerts.push({ msg: 'global.errors.errorSendMailMaxSize'});
                        }else{
                            $scope.alerts.push({msg: 'global.errors.errorSendMail'});
                        }
                        loading.$parent.showLoadingdivSignin = false;
                    }).catch(function(error) {
                    if(error && error.data && error.data.message == 'Mail max size exceded not found'){
                        $scope.alerts.push({ msg: 'global.errors.errorSendMailMaxSize'});
                    }else{
                        $scope.alerts.push({ msg: 'global.errors.errorSendMail'});
                    }
                    loading.$parent.showLoadingdivSignin = false;
                });

            }
        };

        $scope.cancel = function() {
            $uibModalInstance.dismiss('cancel');
        }

        $scope.closeAlert = function(index){
            $scope.alerts.splice(index,1);
        }
    }]);

angular.module('annexaApp')
    .directive('sendmail',['$uibModal', function ($uibModal) {
        return {
            transclude: true,
            restrict: 'EA',
            template: '<a href="" class="{{linkclass}}" ng-click="open()" ng-transclude>{{name}}</a>',
            scope: {
                linkclass: '@',
                docid: "@",
                idUser: "@",
                idDoc: "@"
            },
            link: function(scope, element, attrs) {
                scope.linkclass = attrs.linkClass;
                scope.open = function() {
                    var uimodalInstance = $uibModal.open({
                        templateUrl: './scripts/directives/sendmail/sendmail.html',
                        controller: 'SendMailController',
                        size: 'md',
                        windowClass: 'black-overlay',
                        backdrop: 'static',
                        resolve: {
                            doc: function() {
                                return {
                                    docid: attrs.docId,
                                    docname: attrs.docName,
                                    idUser: attrs.idUser,
                                    idDoc: attrs.idDoc,
                                    docnameattachments:[]
                                };
                            }
                        }
                    });

                    uimodalInstance.result.then(function() {
                        console.log('Finished');
                    }, function() {
                       console.log('Modal dismissed at: ' + new Date());
                    });
                };
            }
        };
    }]);

