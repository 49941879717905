/**
 * Created by osirvent on 10/11/2015.
 */
angular
    .module('annexaApp')
    .controller('SignInController',['$rootScope', '$scope', 'LoginFactory', 'LoginService', '$http', '$translate', 'tmhDynamicLocale', 'Language', function ($rootScope, $scope, LoginFactory, LoginService, $http, $translate, tmhDynamicLocale, Language) {
        $scope.form = new AnnexaFormly(new AnnexaFormlySubmitButton('btn primary btn-block p-x-md','global.sigin.enter'));
        // $scope.form.addField('username', 'annexaLoginInput','', new AnnexaFormlyFieldTemplateOptions('text','global.literals.user',true,true));
        $scope.form.addField('username', 'annexaLoginInput','', new AnnexaFormlyFieldTemplateOptions('text','global.sigin.user',true,true));
        $scope.form.addField('password', 'annexaLoginInput','', new AnnexaFormlyFieldTemplateOptions('password','global.sigin.password', true));

        $scope.errors = {};

        $scope.login = function() {
            $rootScope.showLoadingdivSignin = true;

            LoginFactory.authenticate($scope.form.model, function(authToken) {
                var authTokenDecoded = JSOG.decode(authToken);

                if(authTokenDecoded.userObject){

                    var loggedUser = authTokenDecoded.userObject;
                    loggedUser.isAuthenticated = true;
                    loggedUser.roles = authTokenDecoded.roles;
                    loggedUser.token = authTokenDecoded.token;
                    loggedUser.tenant = authTokenDecoded.tenant;
                    loggedUser.apiUrl = $rootScope.app.baseUrl + 'api/users/' + loggedUser.id;
                    loggedUser.managedOrgans = authTokenDecoded.managedOrgans;

                    if(loggedUser.language && loggedUser.language.acronym){
                        $translate.preferredLanguage(loggedUser.language.acronym);
                        $translate.use(loggedUser.language.acronym).then(function(){
                            $translate.refresh();
                        });
                        tmhDynamicLocale.set(loggedUser.language.acronym);
                        $rootScope.app.language = loggedUser.language.acronym;
                        Language.setCurrent(loggedUser.language.acronym);
                        $rootScope.app.langColumn = Language.getActiveColumn();
                    }

                    LoginService.PostLoginActions(
                        authTokenDecoded.userObject,
                        authTokenDecoded.roles,
                        authTokenDecoded.token,
                        undefined,
                        authTokenDecoded.tenant,
                        authTokenDecoded.modules,
                        authTokenDecoded.managedOrgans,
                        authTokenDecoded.addAutomaticActionPermissions,
                        authTokenDecoded.annexaVersion,
                        authTokenDecoded.activeMultipleRegisterOffice,
                        authTokenDecoded.registerEntryOffices
                    );
                } else {
                    $rootScope.showLoadingdivSignin = false;
                    $scope.authenticationError = true;
                    if(authTokenDecoded && authTokenDecoded.message == "Error on authenticate LDAP"){
                        $scope.errorMessage = "global.sigin.errorLDAP";
                    }else{
                        $scope.errorMessage = "global.sigin.error";
                    }
                }
            }, function (error) {
                $rootScope.showLoadingdivSignin = false;
                $scope.authenticationError = true;
                if(error && error.data && error.data.message == "Error on authenticate LDAP"){
                    $scope.errorMessage = "global.sigin.errorLDAP";
                }else{
                    $scope.errorMessage = "global.sigin.error";
                }
            });
        };
    }]);