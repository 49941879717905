angular.module('annexaApp')
    .directive('headbuttons',['$compile', '$rootScope', 'TramFactory', function ($compile, $rootScope, TramFactory) {
        var getTemplate = function (HeadButtons) {
            var ret = '';

            HeadButtons.forEach(function(item) {
                if(item.dropdownButtons && item.dropdownButtons.length > 0){
                    if(item.btClass) {
                        ret += '<div class="'+item.btClass+'">';
                    }else{
                        ret += '<div class=" btn-group dropdown m-r-sm">';
                    }
                    var contentDropdown = '';
                    contentDropdown += '    <button class="btn primary dropdown-toggle w-full" data-toggle="dropdown"';
                    
                    if (item.permissions && item.permissions.length > 0) {
                    	contentDropdown += ' permission permission-only="[';
                        var first = true;
                        angular.forEach(item.permissions, function (permission) {
                            if (!first) {
                            	contentDropdown += ',';
                            } else {
                                first = false;
                            }

                            contentDropdown += "'" + permission + "'";
                        })
                        contentDropdown += ']"'
                    }

                    contentDropdown += ' >';
                    
                    if (item.btIcon) {
                        contentDropdown = contentDropdown + '<i aria-hidden="true" class="fa ' + item.btIcon + '"></i> ';
                    }
                    if (item.btText) {
                        contentDropdown = contentDropdown + ' <span translate="' + item.btText + '">' + item.btText + '</span>';
                    }
                    if (item.btText2) {
                        contentDropdown = contentDropdown + ' <span translate="' + item.btText2 + '">' + item.btText2 + '</span>';
                    }
                    contentDropdown += '</button>';
                    if(item.groupClass){
                        contentDropdown += '    <div class="'+item.groupClass+'">';
                    }else{
                        contentDropdown += '    <div class="dropdown-menu primary pull-right">';
                    }
                    var contentDropdownButtons = '';
                    
                    if (item.stateParams) {
                    	if (item.stateParams.dossier) {
                    		var dossierId = item.stateParams.dossier;
                    	}
                    	if (item.stateParams.idLoggedUser) {
                    		var userId = item.stateParams.idLoggedUser;
                    	}
                    	if (item.stateParams.currentURL) {
                    		var currentURL = item.stateParams.currentURL;
                    	}
                    }
                    
                    angular.forEach(item.dropdownButtons,function(value, key){
                        contentDropdownButtons += '<a class="dropdown-item" ';                        
                        if (value.href) {
                        	var href = value.href;
                        	
                        	//Reemplaza los parametros de las acciones href utilizados en el plugin actions_uibutton
                        	if (dossierId) {
                        		href = href.replace("{dossierId}", dossierId);
                        	}
                        	if (userId) {
                        		href = href.replace("{userId}", userId);
                        	}
                        	if (currentURL) {
                        		href = href.replace("{urlOrigin}", encodeURIComponent(currentURL));
                        	}
                        	
                        	contentDropdownButtons = contentDropdownButtons + ' ng-href="' + href + '"';
                        	if (value.openInNewWindow) {
                        		contentDropdownButtons = contentDropdownButtons + ' target="_blank"';
                        	} else {
                        		contentDropdownButtons = contentDropdownButtons + ' target="_self"';
                        	}
                        } else if (value.eClick) {
                            if (value.form) {
                                contentDropdownButtons = contentDropdownButtons + ' compile-data="true" eventclick="' + value.eClick + '"';
                            } else {
                                contentDropdownButtons = contentDropdownButtons + ' compile-data eventclick="' + value.eClick + '"';
                            }
                        }
                        if (value.dataToggle && value.dataTarget) {
                            contentDropdownButtons = contentDropdownButtons + ' divcontrol="' + value.dataTarget + '" data-toggle="' + value.dataToggle + '" data-target="' + value.dataTarget + '"';
                        } else if (value.dataTarget) {
                            contentDropdownButtons = contentDropdownButtons + ' divcontrol="' + value.dataTarget + '" ';
                        }
                        if (value.permissions && value.permissions.length > 0) {
                            contentDropdownButtons += ' permission permission-only="[';
                            var first = true;
                            angular.forEach(value.permissions, function (permission) {
                                if (!first) {
                                    contentDropdownButtons += ',';
                                } else {
                                    first = false;
                                }

                                contentDropdownButtons += "'" + permission + "'";
                            })
                            contentDropdownButtons += ']"'
                        }
                        // si no s'informen els procedures no entrarà dins d'aquesta condició, i per tant, es mostrarà sempre l'acció
                        if (value.procedures && value.procedures.length > 0) {
                        	var first = true;
                        	var procedureList = '[';
                        	angular.forEach(value.procedures, function (procedure) {
                                if (!first) {
                                	procedureList += ',';
                                } else {
                                    first = false;
                                }

                                procedureList += "'" + procedure + "'";
                            });
                        	procedureList += ']';
                            
                    		contentDropdownButtons += ' ng-if="existsProcedure(' + procedureList + ')"';
                        }
                        contentDropdownButtons += '>';
                        if (value.btIcon) {
                            contentDropdownButtons = contentDropdownButtons + '<i class="fa ' + value.btIcon + '"></i> ';
                        }
                        if (value.btText) {
                            contentDropdownButtons = contentDropdownButtons + ' <span translate="' + value.btText + '">' + value.btText + '</span>';
                        }
                        if (value.btText2) {
                            contentDropdownButtons = contentDropdownButtons + ' <span translate="' + value.btText2 + '">' + value.btText2 + '</span>';
                        }
                        contentDropdownButtons +="</a>";
                    });

                    if(contentDropdownButtons != '') {
                        ret += contentDropdown + contentDropdownButtons + '</div>';
                    }

                    ret += '</div>';

                }else {
                    ret = ret + '<button';
                    if (item.id) {
                        ret = ret + ' id="' + item.id + '"';
                    }
                    if (item.btClass) {
                        ret = ret + ' class="' + item.btClass + '"';
                    }
                    if (item.eClick) {
                        if (item.form) {
                            ret = ret + ' compile-data="true" eventclick="' + item.eClick + '"';
                        } else {
                            ret = ret + ' compile-data eventclick="' + item.eClick + '"';
                        }
                    }
                    if (item.eDisabled) {
                        ret = ret + ' ng-disabled="getSelectedCount(\''+item.dataTarget+'\', \''+item.eDisabled+'\')"';
                    }
                    if (item.dataToggle && item.dataTarget) {
                        ret = ret + ' divcontrol="' + item.dataTarget + '" data-toggle="' + item.dataToggle + '" data-target="' + item.dataTarget + '"';
                    } else if (item.dataTarget) {
                        ret = ret + ' divcontrol="' + item.dataTarget + '" ';
                    }
                    if (item.permissions && item.permissions.length > 0) {
                        ret += ' permission permission-only="[';
                        var first = true;
                        angular.forEach(item.permissions, function (permission) {
                            if (!first) {
                                ret += ',';
                            } else {
                                first = false;
                            }

                            ret += "'" + permission + "'";
                        })
                        ret += ']"'
                    }
                    ret = ret + '>'
                    if (item.btIcon) {
                        ret = ret + '<i aria-hidden="true" class="fa ' + item.btIcon + '"></i> ';
                    }
                    if (item.btText) {
                        ret = ret + ' <span translate="' + item.btText + '">' + item.btText + '</span>';
                    }
                    if (item.btText2) {
                        ret = ret + ' <span translate="' + item.btText2 + '">' + item.btText2 + '</span>';
                    }
                    ret = ret + '</button>';
                }
            });
            return ret;
        };
        return {
            restrict: 'E',
            link: function(scope, element, attrs, ctrl, transclude) {
            	scope.existsProcedure = function(procedures) {
                	var procedureDossier;
                	if (TramFactory.Dossier && TramFactory.Dossier.procedure) {
                		procedureDossier = TramFactory.Dossier.procedure;
                	}

                    var existsProcedure = false;
                    if (procedureDossier && procedureDossier.acronym) {
                        angular.forEach(procedures, function (procedure) {
                            if (procedureDossier.acronym == procedure) {
                            	existsProcedure = true;
                            }
                        });
                    }
                    return existsProcedure;            		
            	};
            	
                scope.getSelectedCount = function(divcontrol, disabledFunction){
                    var disabled = false;
                    if(divcontrol && disabledFunction) {
                        var aux = angular.element(divcontrol).scope();
                        if(aux && aux[disabledFunction]) {
                            var a = aux[disabledFunction]();
                            if (a == 0) {
                                disabled = true;
                            }
                        }
                    }
                    return disabled;
                };

                if (attrs.subhead) {
                    $rootScope.$watch('subHeadButtons', function (newValue, oldValue) {
                        var domElement = $compile(getTemplate(newValue))(scope);
                        element.empty();
                        element.append(domElement);
                    });
                } else {
                    $rootScope.$watch('headButtons', function (newValue, oldValue) {
                        var domElement = $compile(getTemplate(newValue))(scope);
                        element.empty();
                        element.append(domElement);
                    });
                }

            }
        }
    }]);
